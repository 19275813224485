import React, { useState, Component } from "react";
import { Link } from "react-router-dom";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faHeart,
  faEnvelopeOpen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

class Tab extends Component {
  constructor(props) {
    super(props);
    const navState = {
      home: true,
      message: false,
      matches: false,
      profile: false,
    };

    // localStorage.setItem("navState", JSON.stringify(navState));

    this.state = navState;

    console.log("navstate", navState);
  }

  matchClicked = () => {
    const navState = {
      home: false,
      message: false,
      matches: true,
      profile: false,
    };
    this.setState(navState);
    localStorage.setItem("navState", JSON.stringify(navState));
  };
  homeClicked = () => {
    const navState = {
      home: true,
      message: false,
      matches: false,
      profile: false,
    };
    this.setState(navState);
    localStorage.setItem("navState", JSON.stringify(navState));
  };
  msgClicked = () => {
    const navState = {
      home: false,
      message: true,
      matches: false,
      profile: false,
    };
    this.setState(navState);
    localStorage.setItem("navState", JSON.stringify(navState));
  };
  profileClicked = () => {
    const navState = {
      home: false,
      message: false,
      matches: false,
      profile: true,
    };
    this.setState(navState);
    localStorage.setItem("navState", JSON.stringify(navState));
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    {
      console.log("updated nav", JSON.parse(localStorage.getItem("navState")));
    }
    const idUser = "me";
    return (
      <nav className="navbar navbar-light">
        <div className="container">
          <ul className="nav navbar-nav pull-xs-right">
            <li
              onClick={this.homeClicked}
              className={`nav-item li-tab-default ${
                JSON.parse(localStorage.getItem("navState")).home
                  ? "li-tab-selected"
                  : ""
              }`}
            >
              <Link to="/" className="nav-link" replace={true}>
                <FontAwesomeIcon
                  className={`${
                    JSON.parse(localStorage.getItem("navState")).home
                      ? "icon-selected"
                      : ""
                  }`}
                  icon={faHouse}
                  size="lg"
                />
                <i
                  style={{ display: "none" }}
                  className={`ion-compose ${
                    JSON.parse(localStorage.getItem("navState")).home
                      ? "icon-text-selected"
                      : ""
                  }`}
                >
                  {LOCALE.home}
                </i>
              </Link>
            </li>

            <li
              onClick={this.matchClicked}
              className={`nav-item li-tab-default advance-search-nav ${
                JSON.parse(localStorage.getItem("navState")).matches
                  ? "li-tab-selected"
                  : ""
              }`}
            >
              <Link to="/viewMatchesList" className="nav-link" replace={true}>
                <FontAwesomeIcon
                  icon={faHeart}
                  size="lg"
                  className={`${
                    JSON.parse(localStorage.getItem("navState")).matches
                      ? "icon-selected"
                      : ""
                  }`}
                />
                <i
                  style={{ display: "none" }}
                  className={`ion-compose ${
                    JSON.parse(localStorage.getItem("navState")).matches
                      ? "icon-text-selected"
                      : ""
                  }`}
                >
                  {LOCALE.matches}
                </i>
              </Link>
            </li>

            <li
              onClick={this.msgClicked}
              className={`nav-item li-tab-default ${
                JSON.parse(localStorage.getItem("navState")).message
                  ? "li-tab-selected"
                  : ""
              }`}
            >
              <Link to="/message" className="nav-link" replace={true}>
                <FontAwesomeIcon
                  className={`${
                    JSON.parse(localStorage.getItem("navState")).message
                      ? "icon-selected"
                      : ""
                  }`}
                  icon={faEnvelopeOpen}
                  size="lg"
                />
                <i
                  className={`ion--gear-a ${
                    JSON.parse(localStorage.getItem("navState")).message
                      ? "icon-text-selected"
                      : ""
                  }`}
                  style={{ display: "none" }}
                >
                  {LOCALE.message}
                </i>
              </Link>
            </li>

            <li
              onClick={this.profileClicked}
              className={`nav-item li-tab-default ${
                JSON.parse(localStorage.getItem("navState")).profile
                  ? "li-tab-selected"
                  : ""
              }`}
            >
              <Link to={`/users/${idUser}`} className="nav-link" replace={true}>
                <FontAwesomeIcon
                  className={`${
                    JSON.parse(localStorage.getItem("navState")).profile
                      ? "icon-selected"
                      : ""
                  }`}
                  icon={faUser}
                  size="lg"
                />
                <i
                  className={`ion--gear-a ${
                    JSON.parse(localStorage.getItem("navState")).profile
                      ? "icon-text-selected"
                      : ""
                  }`}
                  style={{ display: "none" }}
                >
                  Profile
                </i>
              </Link>
            </li>

            {/* <li className="nav-item advance-search-nav">
                            <Link to="/search" className="nav-link" replace={true}>
                                <i className="ion-compose">{LOCALE.search}</i>
                            </Link>
                        </li> */}
            {/* <li className="nav-item li-tab-default">
              <DrawerToggleButton click={this.props.drawerClickHandler} />
              <Link to="/users/me" className="nav-link" replace={false}>
                 <i className="ion-gear-a">Profile</i>
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Tab;
