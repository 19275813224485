export const friendsConstants = {

    FETCH_FRIENDS_LISTS_REQUEST: 'FETCH_FRIENDS_LISTS_REQUEST',
    FETCH_FRIENDS_LISTS_SUCCESS: 'FETCH_FRIENDS_LISTS_SUCCESS',
    FETCH_FRIENDS_LISTS_FAILURE: 'FETCH_FRIENDS_LISTS_FAILURE',

    FETCH_FRIENDS_REQUEST_LISTS_REQUEST: 'FETCH_FRIENDS_REQUEST_LISTS_REQUEST',
    FETCH_FRIENDS_REQUEST_LISTS_SUCCESS: 'FETCH_FRIENDS_REQUEST_LISTS_SUCCESS',
    FETCH_FRIENDS_REQUEST_LISTS_FAILURE: 'FETCH_FRIENDS_REQUEST_LISTS_FAILURE',

    FETCH_PENDING_FRIEND_LISTS_REQUEST: 'FETCH_PENDING_FRIEND_LISTS_REQUEST',
    FETCH_PENDING_FRIEND_LISTS_SUCCESS: 'FETCH_PENDING_FRIEND_LISTS_SUCCESS',
    FETCH_PENDING_FRIEND_LISTS_FAILURE: 'FETCH_PENDING_FRIEND_LISTS_FAILURE',

    RESPOND_FRIEND_REQUEST: 'RESPOND_FRIEND_REQUEST',
    RESPOND_FRIEND_SUCCESS: 'RESPOND_FRIEND_SUCCESS',
    RESPOND_FRIEND_FAILURE: 'RESPOND_FRIEND_FAILURE',

    CHECK_FRIEND_STATUS_REQUEST: 'CHECK_FRIEND_STATUS_REQUEST',
    CHECK_FRIEND_STATUS_SUCCESS: 'CHECK_FRIEND_STATUS_SUCCESS',
    CHECK_FRIEND_STATUS_FAILURE: 'CHECK_FRIEND_STATUS_FAILURE',

    FRIEND_SEND_REQUEST: 'FRIEND_SEND_REQUEST',
    FRIEND_CANCEL_REQUEST: 'FRIEND_CANCEL_REQUEST',
    FRIEND_ACCEPT_REQUEST: 'FRIEND_ACCEPT_REQUEST',
    FRIEND_DECLINE_REQUEST: 'FRIEND_DECLINE_REQUEST',
    FRIEND_UNFRIEND: 'FRIEND_UNFRIEND',

    LISTEN_FRIENDS_REQUEST: 'LISTEN_FRIENDS_REQUEST',
    FRIEND_REQUEST_RECEIVED: 'FRIEND_REQUEST_RECEIVED'
};
