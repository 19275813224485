export const profileConstants = {
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

  VIEW_PROFILE_REQUEST: "VIEW_PROFILE_REQUEST",
  VIEW_PROFILE_SUCCESS: "VIEW_PROFILE_SUCCESS",
  VIEW_PROFILE_FAILURE: "VIEW_PROFILE_FAILURE",

  GET_PROFILE_QUESTION_REQUEST: "GET_PROFILE_QUESTION_REQUEST",
  GET_PROFILE_QUESTION_SUCCESS: "GET_PROFILE_QUESTION_SUCCESS",
  GET_PROFILE_QUESTION_FAILURE: "GET_PROFILE_QUESTION_FAILURE",

  GET_MY_GALLERY_REQUEST: "GET_MY_GALLERY_REQUEST",
  GET_MY_GALLERY_SUCCESS: "GET_MY_GALLERY_SUCCESS",
  GET_MY_GALLERY_FAILURE: "GET_MY_GALLERY_FAILURE",

  GET_USER_GALLERY_REQUEST: "GET_USER_GALLERY_REQUEST",
  GET_USER_GALLERY_SUCCESS: "GET_USER_GALLERY_SUCCESS",
  GET_USER_GALLERY_FAILURE: "GET_USER_GALLERY_FAILURE",
};
