import {membershipPlansConstants} from "../constants/membershipPlansConstants";

const initialState = {
    getMembershipPlansState: {
        gettingPlans: false,
        invalidData: false,
        internalError: false,
        responseData: [],
        errorMessage: ''
    },
    getMembershipPlanDetailsState: {
        gettingDetails: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    purchaseMembershipPlansState: {
        purchasingPlan: false,
        invalidDataPlan: false,
        internalErrorPlan: false,
        showModal:false,
        purchaseResponseData: null,
        errorMessagePlan: ''
    },
};

const getMembershipPlansReducer = (state = initialState.getMembershipPlansState, action) => {
    switch (action.type) {
        case membershipPlansConstants.GET_MEMBERSHIP_PLANS_REQUEST:
            return {...initialState.getMembershipPlansState, gettingPlans: true};
        case membershipPlansConstants.GET_MEMBERSHIP_PLANS_SUCCESS:
            return {...initialState.getMembershipPlansState, responseData: action.responseData};
        case membershipPlansConstants.GET_MEMBERSHIP_PLANS_FAILURE: {
            switch (action.errorCode) {
                case 400: {
                    return {
                        ...initialState.getMembershipPlansState,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                case 401: {
                    return {
                        ...initialState.getMembershipPlansState,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                case 409: {
                    return {
                        ...initialState.getMembershipPlansState,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                default: {
                    return {
                        ...initialState.getMembershipPlansState,
                        internalError: true
                    }
                }

            }
        }
        default:
            return state;
    }
};
const getMembershipPlanDetailsReducer = (state = initialState.getMembershipPlanDetailsState, action) => {
    switch (action.type) {
        case membershipPlansConstants.GET_MEMBERSHIP_PLANS_DETAIL_REQUEST:
            return {...initialState.getMembershipPlanDetailsState, gettingDetails: true};
        case membershipPlansConstants.GET_MEMBERSHIP_PLANS_DETAIL_SUCCESS:
            return {...initialState.getMembershipPlanDetailsState, responseData: action.responseData};
        case membershipPlansConstants.GET_MEMBERSHIP_PLANS_DETAIL_FAILURE: {
            switch (action.errorCode) {
                case 400: {
                    return {
                        ...initialState.getMembershipPlanDetailsState,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                case 401: {
                    return {
                        ...initialState.getMembershipPlanDetailsState,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                case 409: {
                    return {
                        ...initialState.getMembershipPlanDetailsState,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                default: {
                    return {
                        ...initialState.getMembershipPlanDetailsState,
                        internalError: true
                    }
                }

            }
        }
        default:
            return state;
    }
};
const purchaseMembershipPlansReducer = (state = initialState.purchaseMembershipPlansState, action) => {
    switch (action.type) {
        case membershipPlansConstants.PURCHASE_MEMBERSHIP_PLANS_REQUEST:
            return {...initialState.purchaseMembershipPlansState, purchasingPlan: true,showModal:true};
        case membershipPlansConstants.PURCHASE_MEMBERSHIP_PLANS_SUCCESS:
            return {...initialState.purchaseMembershipPlansState, purchaseResponseData: action.responseData,showModal:true};
        case membershipPlansConstants.PURCHASE_MEMBERSHIP_PLANS_FAILURE: {
            switch (action.errorCode) {
                case 400: {
                    return {
                        ...initialState.purchaseMembershipPlansState,
                        errorMessagePlan: action.errorMessage,
                        invalidDataPlan: true,
                        showModal:true
                    }
                }
                case 401: {
                    return {
                        ...initialState.purchaseMembershipPlansState,
                        errorMessagePlan: action.errorMessage,
                        invalidDataPlan: true,
                        showModal:true
                    }
                }
                case 409: {
                    return {
                        ...initialState.purchaseMembershipPlansState,
                        errorMessagePlan: action.errorMessage,
                        invalidDataPlan: true,
                        showModal:true
                    }
                }
                default: {
                    return {
                        ...initialState.getMembershipPlansState,
                        internalErrorPlan: true,
                        showModal:true
                    }
                }

            }
        }
        default:
            return state;
    }
};
export {getMembershipPlansReducer, purchaseMembershipPlansReducer, getMembershipPlanDetailsReducer}
