import { apiService } from "../services/apiService";
import { utils } from "../helper/utils";
import { winksConstants } from "../constants/winksConstants";
import firebase from "firebase";
import "firebase/database";

export const winkActions = {
  getReceivedWinksList,
  getAllFlirtsList,
  sendWinks,
};

function getReceivedWinksList() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .receivedWinks()
      .then((response) => {
        console.log("getwinks response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: winksConstants.FETCH_RECEIVED_WINKS_LISTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: winksConstants.FETCH_RECEIVED_WINKS_LISTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: winksConstants.FETCH_RECEIVED_WINKS_LISTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getAllFlirtsList() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getFlirts()
      .then((response) => {
        console.log("getwinks response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: winksConstants.FETCH_ALL_FLIRTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: winksConstants.FETCH_ALL_FLIRTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: winksConstants.FETCH_ALL_FLIRTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function sendWinks(idUser, winkId) {
  console.log("wink action", idUser, winkId);

  return (dispatch) => {
    dispatch(request());
    apiService
      .sendWink(idUser, winkId)
      .then((response) => {
        console.log("getwinks response=");
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: winksConstants.SEND_WINK_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: winksConstants.SEND_WINK_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: winksConstants.SEND_WINK_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
