import {accessFeatureConstants} from "../constants/accessFeatureConstants";

const initialState = {
    accessFeatureState: {
        verifyingAccess: false,
        invalidData: false,
        internalError: false,
        access: false,
        errorMessage: ''
    },
};


const accessFeatureReducer = (state = initialState.accessFeatureState, action) => {
    switch (action.type) {
        case accessFeatureConstants.CHECK_ACCESS_FEATURE_REQUEST:

            return {...state, verifyingAccess: true};
        case accessFeatureConstants.CHECK_ACCESS_FEATURE_SUCCESS:
            return {
                ...state,
                access: true,
                verifyingAccess: false,
            };
        case accessFeatureConstants.CHECK_ACCESS_FEATURE_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.accessFeatureState,
                        access: false,
                        verifyingAccess: false,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.accessFeatureState,
                        access: false,
                        verifyingAccess: false,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};
export {accessFeatureReducer};
