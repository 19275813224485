import React, { Component } from "react";
import { friendActions } from "../../actions/friendActions";
import { connect } from "react-redux";
import { history } from "../app/App";
import { utils } from "../../helper/utils";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import { friendsConstants } from "../../constants/friendsConstants";
import Modal from "react-modal";

class SentFriendRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      friend: null,
    };
  }
  componentDidMount() {
    this.props.sentFriendsRequestList();
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };
  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };
  cancelFriendRequest = (id) => {
    this.setState({
      showModal: true,
      friend: id,
    });
  };

  confirmCancel = (id) => {
    console.log("cancel req", id);
    this.props.respondFriend(
      this.state.friend,
      friendsConstants.FRIEND_CANCEL_REQUEST
    );
    this.setState({
      showModal: false,
    });
  };

  paginateIfNecessary = (e) => {
    const { gettingSentRequests, lastPage, page } =
      this.props.sentFriendsRequestListReducer;
    console.log("paginateIfNecessary", {
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      clientHeight: e.target.clientHeight,
    });
    const bottom =
      Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) ===
      Math.round(e.target.clientHeight);

    console.log("paginateIfNecessary bottom", bottom);

    if (bottom) {
      if (lastPage || gettingSentRequests) {
        gettingSentRequests
          ? console.log("getting search users already")
          : console.log("last page reached");
      } else {
        console.log("getting more users", page);
        this.props.sentFriendsRequestList(page + 1);
      }
    }
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      gettingSentRequests,
      sentFriendRequests,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.sentFriendsRequestListReducer;
    console.log("searchResults render users=", sentFriendRequests);
    if (sentFriendRequests.length > 0) {
      return (
        <div className="home-page-wrapper search-result">
          <div className="home-page">
            {/* <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.pending_requests}</h3>
            </div> */}
            <div className="scrollx" onScroll={this.paginateIfNecessary}>
              {sentFriendRequests.map((sentFriendRequest, i) => (
                <div
                  // onClick={this.handleImageClick.bind(
                  //   this,
                  //   sentFriendRequest.user_id
                  // )}
                  key={i}
                >
                  <div className="searched-image">
                    <div className="searched-img">
                      <img
                        key={i}
                        className="img-search"
                        src={sentFriendRequest.user_image_path.image_250}
                        alt="user"
                        onClick={this.handleImageClick.bind(
                          this,
                          sentFriendRequest.user_id
                        )}
                      />
                    </div>
                    <div className="searched-det">
                      <label>{`${sentFriendRequest.user_display_name}`}</label>

                      {/* <label>{sentFriendRequest.countryName}, </label>
                      {sentFriendRequest.stateName !== "None" ? (
                        <label>{sentFriendRequest.stateName}, </label>
                      ) : (
                        ""
                      )}
                      <label>{sentFriendRequest.cityName}</label> */}
                    </div>
                    <div
                      // onClick={this.cancelFriendRequest(
                      //   sentFriendRequest.user_id
                      // )}
                      onClick={(e) =>
                        this.cancelFriendRequest(sentFriendRequest.friend_id)
                      }
                      className="respond-icons-cancel-sent"
                    >
                      <span class="material-symbols-outlined">close</span>
                    </div>
                    <div class="line"></div>
                  </div>
                </div>
              ))}
            </div>
            {gettingSentRequests && <Loader />}

            {/*<div>Back to Profile?*/}
            {/*    <button className="btn-clear top-space"*/}
            {/*            onClick={() => {*/}
            {/*                history.goBack();*/}

            {/*            }}><strong>Click here</strong>*/}
            {/*    </button>*/}
            {/*</div>*/}
          </div>
          <Modal
            isOpen={this.state.showModal}
            contentLabel="Minimal Modal Example"
            ariaHideApp={false}
            onClose={this.handleCloseModal}
          >
            <img
              className="cancel-icon"
              onClick={this.handleCloseModal}
              src={require("../../img/cancel.png")}
              alt="cancel-button"
            />
            <h3>Cancel Request</h3>

            <p>Are you sure you want to cancel request?</p>
            <button onClick={this.handleCloseModal}>{LOCALE.no}</button>
            <button onClick={this.confirmCancel}>{LOCALE.yes}</button>
          </Modal>
        </div>
      );
    } else if (gettingSentRequests) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            {/* <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.pending_requests}</h3>
            </div> */}
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            {/* <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.pending_requests}</h3>
            </div> */}
            <div className="homepage-content">
              {/*
                            {gettingSentRequests && <div>Getting Sent Friend Requests...</div>}
*/}
              {internalError && <h3>{LOCALE.internal_error}</h3>}
              {invalidData && <h3>{errorMessage}</h3>}
              <h3>{LOCALE.no_pending_requests}</h3>
              {/* <button
                className="btn00"
                onClick={() => {
                  history.push("/search");
                }}
              >
                {" "}
                {LOCALE.searchFriends}
              </button> */}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    sentFriendsRequestListReducer: state.sentFriendsRequestListReducer,
    respondFriendReducer: state.respondFriendReducer,
    checkFriendStatusReducer: state.checkFriendStatusReducer,
  };
};
const actionCreators = {
  sentFriendsRequestList: friendActions.sentFriendsRequestList,
  respondFriend: friendActions.respondFriend,
  checkFriendStatus: friendActions.checkFriendStatus,
};
export default connect(mapState, actionCreators)(SentFriendRequests);
