import {friendsConstants} from "../constants/friendsConstants";

const initialState = {
    getFriendsList: {
        gettingFriends: false,
        invalidData: false,
        internalError: false,
        friends: [],
        nextPage: 0,
        errorMessage: ''
    },
    getSentFriendsRequestList: {
        gettingSentRequests: false,
        invalidData: false,
        internalError: false,
        sentFriendRequests: [],
        nextPage: 0,
        errorMessage: ''
    },
    pendingFriendRequestState: {
        gettingPendingRequests: false,
        invalidData: false,
        internalError: false,
        pendingFriendRequests: [],
        nextPage: 0,
        errorMessage: ''
    },
    respondFriendState: {
        responding: false,
        invalidData: false,
        internalError: false,
        errorMessage: '',
        responseData: ''
    },
    friendRequestState: {
        friend: {},
        total: 0,
        ids: []
    },
    checkFriendStatusState: {
        checking: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    }
};

const getFriendsListReducer = (state = initialState.getFriendsList, action) => {
    switch (action.type) {
        case friendsConstants.FETCH_FRIENDS_LISTS_REQUEST:
            if (action.reset) {
                return {...initialState.getFriendsList, gettingFriends: true};
            }
            return {...state, gettingFriends: true};
        case friendsConstants.FETCH_FRIENDS_LISTS_SUCCESS:
            let {friends} = state;
            if (action.reset) {
                friends = [];
            }

            const newFriends = action.responseData.data;
            const {pagination} = action.responseData.meta;
            for (let i = 0; i < newFriends.length; i++) {
                friends.push(newFriends[i]);
            }
            console.log('getFriendsLists friends=', friends);
            return {
                ...state,
                friends,
                totalFriends: friends.length,
                gettingFriends: false,
                page: pagination.current_page,
                lastPage: pagination.current_page === pagination.total_pages
            };
        case friendsConstants.FETCH_FRIENDS_LISTS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.getFriendsList,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getFriendsList,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const sentFriendsRequestListReducer = (state = initialState.getSentFriendsRequestList, action) => {
    switch (action.type) {
        case friendsConstants.FETCH_FRIENDS_REQUEST_LISTS_REQUEST:
            if (action.reset) {
                return {...initialState.getSentFriendsRequestList, gettingSentRequests: true};
            }
            return {...state, gettingSentRequests: true};
        case friendsConstants.FETCH_FRIENDS_REQUEST_LISTS_SUCCESS:
            let {sentFriendRequests} = state;
            if (action.reset) {
                sentFriendRequests = [];
            }
            const newSentFriendRequests = action.responseData.data;
            const {pagination} = action.responseData.meta;
            for (let i = 0; i < newSentFriendRequests.length; i++) {
                sentFriendRequests.push(newSentFriendRequests[i]);
            }
            console.log('getFriendsLists friends=', sentFriendRequests.length);
            return {
                ...state,
                sentFriendRequests,
                totalFriends: sentFriendRequests.length,
                gettingSentRequests: false,
                page: pagination.current_page,
                lastPage: pagination.current_page === pagination.total_pages
            };
        case friendsConstants.FETCH_FRIENDS_REQUEST_LISTS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.getSentFriendsRequestList,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getSentFriendsRequestList,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const pendingFriendRequestsReducer = (state = initialState.pendingFriendRequestState, action) => {
    switch (action.type) {
        case friendsConstants.FETCH_PENDING_FRIEND_LISTS_REQUEST:
            if (action.reset) {
                return {...initialState.pendingFriendRequestState, gettingPendingRequests: true};
            }
            return {...state, gettingPendingRequests: true};
        case friendsConstants.FETCH_PENDING_FRIEND_LISTS_SUCCESS:
            let {pendingFriendRequests} = state;
            if (action.reset) {
                pendingFriendRequests = [];
            }
            const newPendingFriendRequests = action.responseData.data;
            const {pagination} = action.responseData.meta;
            for (let i = 0; i < newPendingFriendRequests.length; i++) {
                pendingFriendRequests.push(newPendingFriendRequests[i]);
            }
            console.log('getFriendsLists friends=', pendingFriendRequests);
            return {
                ...state,
                pendingFriendRequests,
                totalFriends: pendingFriendRequests.length,
                gettingPendingRequests: false,
                page: pagination.current_page,
                lastPage: pagination.current_page === pagination.total_pages
            };
        case friendsConstants.FETCH_PENDING_FRIEND_LISTS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.pendingFriendRequestState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.pendingFriendRequestState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const respondFriendReducer = (state = initialState.respondFriendState, action) => {
    switch (action.type) {
        case friendsConstants.RESPOND_FRIEND_REQUEST:
            return {...initialState.respondFriendState, responding: true};
        case friendsConstants.RESPOND_FRIEND_SUCCESS:
            return {
                ...initialState.respondFriendState,
                responseData: action.responseData
            };
        case friendsConstants.RESPOND_FRIEND_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.respondFriendState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.respondFriendState,
                        internalError: true
                    }
            }
        default:
            return initialState.respondFriendState;
    }
};

const listenFriendRequestReducer = (state = initialState.friendRequestState, action) => {
    switch (action.type) {
        case friendsConstants.FRIEND_REQUEST_RECEIVED:
            const {id} = action.friend;
            const {ids} = state;
            if (!ids.includes(id)) {
                ids.push(id);
            }
            return {friend: action.friend, ids: ids, total: ids.length};
        default:
            return state;
    }
};

const checkFriendStatusReducer = (state = initialState.checkFriendStatusState, action) => {
    switch (action.type) {
        case friendsConstants.CHECK_FRIEND_STATUS_REQUEST:
            return {...initialState.checkFriendStatusState, checking: true};
        case friendsConstants.CHECK_FRIEND_STATUS_SUCCESS:
            return {
                ...initialState.checkFriendStatusState,
                responseData: action.responseData
            };
        case friendsConstants.CHECK_FRIEND_STATUS_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.checkFriendStatusState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.checkFriendStatusState,
                        internalError: true
                    }
            }
        default:
            return initialState.checkFriendStatusState;
    }
};

export {
    getFriendsListReducer,
    sentFriendsRequestListReducer,
    pendingFriendRequestsReducer,
    respondFriendReducer,
    listenFriendRequestReducer,
    checkFriendStatusReducer
}
