import { viewsConstants } from "../constants/viewsConstants";

const initialState = {
  getViewsList: {
    gettingViews: false,
    invalidData: false,
    internalError: false,
    sentviews: [],
    receivedviews: [],
    nextPage: 0,
    errorMessage: "",
  },
};

const getSentViewsListReducer = (state = initialState.getViewsList, action) => {
  switch (action.type) {
    case viewsConstants.FETCH_SENT_VIEWS_LISTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getViewsList, gettingViews: true };
      }
      return { ...state, gettingViews: true };
    case viewsConstants.FETCH_SENT_VIEWS_LISTS_SUCCESS:
      let { sentviews } = state;
      if (action.reset) {
        sentviews = [];
      }

      const newViews = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      for (let i = 0; i < newViews.length; i++) {
        sentviews.push(newViews[i]);
      }
      console.log("sent views list=", sentviews);
      return {
        ...state,
        sentviews,
        totalViews: sentviews.length,
        gettingViews: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case viewsConstants.FETCH_SENT_VIEWS_LISTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getViewsList,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getViewsList,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const getReceivedViewsListReducer = (
  state = initialState.getViewsList,
  action
) => {
  switch (action.type) {
    case viewsConstants.FETCH_RECEIVED_VIEWS_LISTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getViewsList, gettingViews: true };
      }
      return { ...state, gettingViews: true };
    case viewsConstants.FETCH_RECEIVED_VIEWS_LISTS_SUCCESS:
      let { receivedviews } = state;
      if (action.reset) {
        receivedviews = [];
      }

      const newViews = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      receivedviews = [];
      for (let i = 0; i < newViews.length; i++) {
        receivedviews.push(newViews[i]);
      }
      console.log("getFriendsLists received views=", receivedviews);
      return {
        ...state,
        receivedviews,
        totalViews: receivedviews.length,
        gettingViews: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case viewsConstants.FETCH_RECEIVED_VIEWS_LISTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getViewsList,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getViewsList,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

export { getSentViewsListReducer, getReceivedViewsListReducer };
