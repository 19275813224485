import {accoutSettingsConstants} from "../constants/accoutSettingsConstants";

const initialState = {
    changeEmail: {
        sendingOtp: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    verifyEmail: {
        verifyingOtp: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    changePassword: {
        updatingPassword: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
};

const requestChangeEmailReducer = (state = initialState.changeEmail, action) => {
    switch (action.type) {
        case accoutSettingsConstants.CHANGE_EMAIL_REQUEST:
            return {...initialState.changeEmail, sendingOtp: true};
        case accoutSettingsConstants.CHANGE_EMAIL_SUCCESS:
            return {...initialState.changeEmail, responseData: action.responseData};
        case accoutSettingsConstants.CHANGE_EMAIL_FAILURE: {
            switch (action.errorCode) {
                case 400: {
                    return {
                        ...initialState.changeEmail,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                case 401: {
                    return {
                        ...initialState.changeEmail,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                case 409: {
                    return {
                        ...initialState.changeEmail,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                default: {
                    return {
                        ...initialState.changeEmail,
                        internalError: true
                    }
                }

            }
        }
        default:
            return state;
    }
};
const requestVerifyEmailReducer = (state = initialState.verifyEmail, action) => {
    switch (action.type) {
        case accoutSettingsConstants.VERIFY_EMAIL_REQUEST:
            return {...initialState.verifyEmail, verifyingOtp: true};
        case accoutSettingsConstants.VERIFY_EMAIL_SUCCESS:
            return {...initialState.verifyEmail, responseData: action.responseData};
        case accoutSettingsConstants.VERIFY_EMAIL_FAILURE: {
            switch (action.errorCode) {
                case 400: {
                    return {
                        ...initialState.verifyEmail,
                        internalError: true
                    }
                }
                case 401: {
                    return {
                        ...initialState.verifyEmail,
                        internalError: true
                    }
                }
                default: {
                    return {
                        ...initialState.verifyEmail,
                        internalError: true
                    }
                }

            }
        }
        default:
            return state;
    }
};

const changePasswordReducer = (state = initialState.changePassword, action) => {
    switch (action.type) {
        case accoutSettingsConstants.CHANGE_PASSWORD_REQUEST:
            return {...initialState.changePassword, updatingPassword: true};
        case accoutSettingsConstants.CHANGE_PASSWORD_SUCCESS:
            return {...initialState.changePassword, responseData: action.responseData};
        case accoutSettingsConstants.CHANGE_PASSWORD_FAILURE: {
            switch (action.errorCode) {
                case 400: {
                    return {

                        ...initialState.changePassword,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                case 401: {
                    return {

                        ...initialState.changePassword,
                        errorMessage: action.errorMessage,
                        invalidData: true
                    }
                }
                default: {
                    return {
                        ...initialState.changePassword,
                        internalError: true
                    }
                }

            }
        }
        default:
            return state;
    }
};

export {requestChangeEmailReducer, requestVerifyEmailReducer, changePasswordReducer}
