export const messageConstants = {
    GET_MESSAGE_USERS_REQUEST: 'GET_MESSAGE_USERS_REQUEST',
    GET_MESSAGE_USERS_SUCCESS: 'GET_MESSAGE_USERS_SUCCESS',
    GET_MESSAGE_USERS_FAILURE: 'GET_MESSAGE_USERS_FAILURE',

    GET_MESSAGES_REQUEST: 'GET_MESSAGES_REQUEST',
    GET_MESSAGES_SUCCESS: 'GET_MESSAGES_SUCCESS',
    GET_MESSAGES_FAILURE: 'GET_MESSAGES_FAILURE',

    SEND_MESSAGE_REQUEST: 'SEND_MESSAGE_REQUEST',
    SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
    SEND_MESSAGE_FAILURE: 'SEND_MESSAGE_FAILURE',

    RESEND_MESSAGE_REQUEST: 'RESEND_MESSAGE_REQUEST',
    NEW_MESSAGE_RECEIVED: 'NEW_MESSAGE_RECEIVED'
};
