import React, { Component } from "react";
import { history } from "../app/App";
import { profileActions } from "../../actions/profileActions";
import { connect } from "react-redux";
import SearchFields from "./searchFields";
import Loader from "../Loader/Loader";
import { helperActions } from "../../actions/helperActions";
import { featureAccessAction } from "../../actions/featureAccessAction";
import { utils } from "../../helper/utils";
import { userConstants } from "../../constants/userConstants";
import { default as LOCALE } from "../../localization";
import { countryActions } from "../../actions/countryActions";

class AdvanceSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seeking: "M",
      countryIndex: 97,
      stateIndex: 0,
      cityIndex: 0,
      age_to: "",
      age_from: "",
      emptyUsername: false,
      sideDrawerOpen: false,
      emptyMinAge: false,
      invalidMinAge: false,
      invalidAgeRange: false,
      emptyMaxAge: false,
    };

    this.answers = {};
    this.props.getCountryList();
    this.props.getStateList();
    this.props.getCityList();
  }

  async componentDidMount() {
    this.props.getProfileQuestions();
    // this.props.loadCountryStateCityList();
    this.props.checkFeatureAccess("advanced search");
    console.log("component did mount called here");

    const { searchParams } = this.props.getSearchUserReducer;

    if (!searchParams) {
      return;
    }

    await this.loadInitialData(searchParams);
  }

  loadInitialData = async (searchParams) => {
    console.log(
      "advanceSearch",
      "the search params are ",
      searchParams.country_id,
      searchParams.city_id
    );

    const {
      seeking,
      country_id,
      state_id,
      city_id,
      age_to,
      age_from,
      additional_options,
    } = searchParams;

    const { countryIndex, stateIndex, cityIndex } =
      await utils.getCountryStateCityIndices(country_id, state_id, city_id);

    this.answers = additional_options;
    console.log(
      "advanceSearch",
      "the search params area ",
      JSON.stringify(this.answers)
    );
    this.setState({
      seeking,
      countryIndex,
      stateIndex,
      cityIndex,
      age_to,
      age_from,
    });

    // this.props.loadCountryStateCityList(countryIndex, stateIndex);
  };

  populateCountryList = () => {
    const { countryList } = this.props.countryReducer;

    let items = [];
    for (let i = 0; i < countryList.length; i++) {
      items.push(
        <option key={i} value={i}>
          {countryList[i]}
        </option>
      );
    }
    return items;
  };

  populateStateList = () => {
    const { stateList } = this.props.countryReducer;

    let items = [];
    for (let i = 0; i < stateList.length; i++) {
      items.push(
        <option key={i} value={i}>
          {stateList[i]}
        </option>
      );
    }
    return items;
  };

  populateCityList = () => {
    const { cityList } = this.props.countryReducer;
    let items = [];
    for (let i = 0; i < cityList.length; i++) {
      items.push(
        <option key={i} value={i}>
          {cityList[i]}
        </option>
      );
    }
    return items;
  };

  onSeekingSelected = (value) => {
    this.setState({ seeking: value });
  };

  onCountrySelected = (selectedCountryIndex) => {
    this.props.getStateList(selectedCountryIndex);
    this.setState({
      countryIndex: selectedCountryIndex,
      stateIndex: 0,
      cityIndex: 0,
    });
  };

  onStateSelected = (selectedStateIndex) => {
    const { countryIndex } = this.state;
    this.props.getCityList(countryIndex, selectedStateIndex);
    // this.props.loadCityList(countryIndex, selectedStateIndex);
    this.setState({ stateIndex: selectedStateIndex, cityIndex: 0 });
  };

  onCitySelected = (selectedCityIndex) => {
    this.setState({ cityIndex: selectedCityIndex });
  };

  onAnswerChanged = (profile_setup_id, answer) => {
    if (!this.answers[profile_setup_id]) {
      this.answers[profile_setup_id] = {};
    }
    this.answers[profile_setup_id].answer = answer;
  };

  onInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  updateProfileQuestions = async () => {
    const { countryIndex, stateIndex, cityIndex, age_from, age_to, seeking } =
      this.state;

    // const { countryId, stateId, cityId } = await utils.getCountryStateCityIds(
    //   countryIndex,
    //   stateIndex,
    //   cityIndex
    // );

    console.log("search parameters", this.state);

    const { countryId, stateId, cityId } = {
      countryIndex,
      stateIndex,
      cityIndex,
    };
    this.setState({
      emptyMinAge: false,
      emptyMaxAge: false,
      invalidMinAge: false,
      invalidAgeRange: false,
    });

    if (age_from.length <= 0) {
      this.setState({
        emptyMinAge: true,
      });
      console.log("updateProfileQuestions=1", age_from);

      return;
    }
    if (age_to.length <= 0) {
      this.setState({
        emptyMaxAge: true,
      });
      return;
    }
    if (parseInt(age_from) < 18) {
      this.setState({
        invalidMinAge: true,
      });
      return;
    }
    if (parseInt(age_to) < parseInt(age_from)) {
      this.setState({
        invalidAgeRange: true,
      });
      return;
    }

    console.log("updateProfileQuestions=", this.state.searchParams);
    console.log(
      "param search",
      seeking,
      countryIndex,
      stateIndex,
      cityIndex,
      age_to,
      age_from,
      this.answers
    );

    history.push("/searchResults", {
      searchParams: {
        seeking,
        country_id: countryIndex,
        state_id: stateIndex,
        city_id: cityIndex,
        age_to,
        age_from,
        additional_options: this.answers,
      },
      searchType: "advance",
    });
  };

  getDiv = (questions) => {
    console.log(
      "componentDidMount.searchParams above =",
      JSON.stringify(this.answers).length,
      Object.keys(this.answers).length
    );
    console.log("search questions", questions);
    const multipleChoiceQuestions = questions.data.filter(
      (question) =>
        (question.field_type_id === "1" && question.display_status === "Y") ||
        (question.field_type_id === "3" && question.display_status === "Y")
    );
    if (!this.answers || Object.keys(this.answers).length === 0) {
      console.log("componentDidMount.searchParams middle =");

      return (
        <div>
          {multipleChoiceQuestions.map((question, i) => (
            <div key={i}>
              <SearchFields
                question={question}
                onAnswerChanged={this.onAnswerChanged}
                onInputError={this.onInputError}
              />
              <br />
            </div>
          ))}

          <button className="btn1" onClick={this.updateProfileQuestions}>
            {LOCALE.search}
          </button>
        </div>
      );
    }

    return (
      <div>
        {multipleChoiceQuestions.map(
          (question, i) => (
            // eslint-disable-next-line
            (question.selected_question_option_ids = this.answers),
            (
              <div key={i}>
                <SearchFields
                  question={question}
                  onAnswerChanged={this.onAnswerChanged}
                  onInputError={this.onInputError}
                />
                <br />
              </div>
            )
          )
        )}

        <button className="btn1" onClick={this.updateProfileQuestions}>
          Search
        </button>
      </div>
    );
  };

  getAccessCheck(verifyingAccess, internalErrors, errorMessages) {
    return (
      <div>
        {!verifyingAccess ? (
          <div className="homepage-content">
            <h3>{LOCALE.please_subscribe_our_feature}</h3>
            <button
              className="btn1"
              onClick={() => {
                history.push("/membershipPlans");
              }}
            >
              {LOCALE.take_me_to_plans}
            </button>
            {internalErrors && <div>{errorMessages}</div>}
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
    );
  }

  getBasicSearch(responseData) {
    const {
      countryIndex,
      stateIndex,
      cityIndex,
      emptyMinAge,
      emptyMaxAge,
      invalidMinAge,
      invalidAgeRange,
      seeking,
      age_from,
      age_to,
    } = this.state;

    console.log(
      "advance search",
      "the city and country are ",
      countryIndex,
      cityIndex,
      stateIndex
    );
    const countriesList =
      this.props?.countryReducer?.responseData?.responseData;
    const stateList = this.props?.stateReducer?.responseData;
    const cityList = this.props?.cityReducer?.responseData;
    return (
      <div className="homepage-content advance-search">
        {/* <h2>{LOCALE.advanced_search}</h2> */}
        <div className="search-basic-info">
          <label className="advance-seeking">
            {LOCALE.seeking_}
            <span>*</span>
          </label>
          <div id="basic-select">
            <select
              className="input-field"
              value={seeking}
              onChange={(e) => {
                this.onSeekingSelected(e.target.value);
              }}
            >
              <option value="M">{LOCALE.male}</option>
              <option value="F">{LOCALE.female}</option>
              <option value="C">{LOCALE.couples}</option>
            </select>
          </div>
          <label>
            {LOCALE.age_}
            <span>*</span>
          </label>
          <div className="age-bar">
            {invalidMinAge && (
              <div className="invalid-age">
                {LOCALE.minAge}
                <br />
              </div>
            )}
            {emptyMinAge && (
              <div className="invalid-age">
                {LOCALE.min_age_Required} <br />
              </div>
            )}
            {emptyMaxAge && (
              <div className="invalid-age">
                {LOCALE.max_age_Required}
                <br />
              </div>
            )}
            {invalidAgeRange && (
              <div className="invalid-age">
                {LOCALE.provide_valid_range} <br />
              </div>
            )}
            <input
              className={
                invalidMinAge || emptyMinAge || invalidAgeRange
                  ? "input-field input-field-error"
                  : "input-field"
              }
              type="number"
              name="age_from"
              defaultValue={age_from}
              placeholder={LOCALE.min_age}
              onChange={this.onInput}
            />
            <label>to</label>
            <input
              className={
                emptyMaxAge || invalidAgeRange
                  ? "input-field input-field-error"
                  : "input-field"
              }
              type="number"
              name="age_to"
              defaultValue={age_to}
              placeholder={LOCALE.max_age}
              onChange={this.onInput}
            />
          </div>

          <label>{LOCALE.country}</label>
          <div id="basic-select">
            <select
              className="input-field"
              // value={countriesList?.data}
              onChange={(e) => {
                this.onCountrySelected(e.target.value);
              }}
            >
              {countriesList?.data.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
              {/* {countriesList?.data} */}
            </select>
          </div>

          <label>{LOCALE.state}</label>
          <div id="basic-select">
            <select
              className="input-field"
              // value={stateIndex}
              onChange={(e) => {
                this.onStateSelected(e.target.value);
              }}
            >
              {stateList?.data.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
              {/* {this.populateStateList()} */}
            </select>
          </div>

          <label>
            {LOCALE.city}
            <span>*</span>
          </label>
          <div id="basic-select">
            <select
              className="input-field"
              // value={cityIndex}
              onChange={(e) => {
                this.onCitySelected(e.target.value);
              }}
            >
              {cityList?.data.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
              {/* {this.populateCityList()}  */}
            </select>
          </div>
        </div>
        {responseData && this.getDiv(responseData)}
      </div>
    );
  }

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      gettingProfileQuestions,
      responseData,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.getProfileQuestionsReducer;
    const {
      updatingProfile,
      invalidDataUpdateProfile,
      internalErrorUpdateProfile,
      errorMessageUpdateProfile,
    } = this.props.profileReducer;
    const { verifyingAccess, access, internalErrors, errorMessages } =
      this.props.accessFeatureReducer;

    return (
      <div>
        <div className="home-page-wrapper search-page">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>Advance Search</h3>
            </div>
            {gettingProfileQuestions && <Loader />}
            {invalidData && <div>{errorMessage}</div>}
            {internalError && <div>{LOCALE.internal_error}</div>}
            {updatingProfile && <div>{LOCALE.updating_profile}</div>}
            {invalidDataUpdateProfile && <div>{errorMessageUpdateProfile}</div>}
            {internalErrorUpdateProfile && <div>{LOCALE.internal_error}</div>}
            {/* {!access
              ? this.getAccessCheck(
                  verifyingAccess,
                  internalErrors,
                  errorMessages
                )
              : this.getBasicSearch(responseData)} */}
            {this.getBasicSearch(responseData)}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    getProfileQuestionsReducer: state.getProfileQuestionsReducer,
    profileReducer: state.profileReducer,
    // countryReducer: state.countryReducer,
    accessFeatureReducer: state.accessFeatureReducer,
    getSearchUserReducer: state.getSearchUserReducer,
    countryReducer: state.getCountryReducer,
    stateReducer: state.getStateReducer,
    cityReducer: state.getCityReducer,
  };
};

const actionCreators = {
  getProfileQuestions: profileActions.getProfileQuestions,
  //   loadCountryStateCityList: helperActions.loadCountryStateCityList,
  //   loadStateCityList: helperActions.loadStateCityList,
  //   loadCityList: helperActions.loadCityList,
  checkFeatureAccess: featureAccessAction.checkFeatureAccess,
  getCountryList: countryActions.getCountries,
  getStateList: countryActions.getStates,
  getCityList: countryActions.getCities,
};

export default connect(mapState, actionCreators)(AdvanceSearch);
