export const accoutSettingsConstants = {
    CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
    CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
    CHANGE_EMAIL_FAILURE: 'CHANGE_EMAIL_FAILURE',

    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

};
