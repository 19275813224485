import React, {Component} from 'react';
import {connect} from 'react-redux'
import {registrationAction} from "../../actions/registrationAction";
import {history} from "../app/App";
import {userConstants} from "../../constants/userConstants";
import {default as LOCALE} from "../../localization";


class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {
                username: '',
                first_name: '',
                last_name: '',
                email: this.props.otpVerifyRegisterReducer.responseData.userEmail,
                password: '',
                fcm_registration_id: localStorage.getItem(userConstants.FCM_TOKEN)
            },

            fieldEmpty: false,
            userNameEmpty: false,
            firstNameEmpty: false,
            lastNameEmpty: false,
            passwordEmpty: false,
            disableEmail: true,
            validPassword: true
        }
    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({
            params: {...this.state.params, [name]: value}
        });
    };

    isValidPassword = (str) => {
        return str.match(/[a-z]/) && str.match(/[A-Z]/) && str.length >= 8;
    };

    isFieldValid = (fieldValue) => {
        return fieldValue.toString().length > 0;
    };

    userRegistration = () => {
        const {
            username,
            first_name,
            last_name,
            password
        } = this.state.params;

        if (!this.isFieldValid(username)) {
            this.setState({userNameEmpty: true});
            return
        }

        this.setState({userNameEmpty: false});

        if (!this.isFieldValid(first_name)) {

            this.setState({firstNameEmpty: true});
            return
        }
        this.setState({firstNameEmpty: false});

        if (!this.isFieldValid(last_name)) {

            this.setState({lastNameEmpty: true});
            return
        }
        this.setState({lastNameEmpty: false});


        if (!this.isValidPassword(password)) {
            this.setState({validPassword: false})
        }
        this.setState({validPassword: true});


        const emailAddress = this.props.otpVerifyRegisterReducer.responseData.userEmail;

        this.setState({
            params: {...this.state.params, email: emailAddress}
        });

        this.props.userRegistration(this.state.params);
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const prevResponse = this.props.otpVerifyRegisterReducer.responseData.userEmail;
        const {
            registeringUser,
            existsUserName,
            errorMessage,
            invalidData,
            internalError,
        } = this.props.userRegisterReducer;
        const {
            username,
            first_name,
            last_name,
            password,
            disableEmail
        } = this.state.params;

        const {
            userNameEmpty,
            firstNameEmpty,
            lastNameEmpty,
            validPassword,
        } = this.state;

        return (
            <div className="login-page">
                <div className="login-form">
                    <h1 className="login-page-title">{LOCALE.register}</h1>

                    <div className="extra-space">
                        <div className={registeringUser ? "login-btn-disable" : ""}>

                            {userNameEmpty && <div>{errorMessage}</div>}
                            {existsUserName && <div>{LOCALE.username}</div>}
                            <input
                                className={userNameEmpty || existsUserName ? "input-field input-field-error" : "input-field"}
                                type='text'
                                name='username'
                                value={username}
                                placeholder={LOCALE.username}
                                onChange={this.onInput}/>

                            {firstNameEmpty && <div>{LOCALE.first_name_required}</div>}

                            <input className={userNameEmpty ? "input-field input-field-error" : "input-field"}
                                   type='text'
                                   name='first_name'
                                   value={first_name}
                                   placeholder={LOCALE.first_name}
                                   onChange={this.onInput}/>

                            {lastNameEmpty && <div>{LOCALE.last_name_required}</div>}
                            <input className={userNameEmpty ? "input-field input-field-error" : "input-field"}
                                   type='text'
                                   name='last_name'
                                   value={last_name}
                                   placeholder={LOCALE.last_name}
                                   onChange={this.onInput}/>


                            <input className="input-field"
                                   disabled={disableEmail}
                                   type='text'
                                   name='email'
                                   value={prevResponse}
                                   placeholder={LOCALE.email}
                                   onChange={this.onInput}/>


                            {!validPassword &&
                            <div>{LOCALE.password_must_contain}</div>}
                            <  input
                                className={userNameEmpty || !validPassword ? "input-field input-field-error" : "input-field"}
                                type='password'
                                name='password'
                                value={password}
                                placeholder={LOCALE.password}
                                onChange={this.onInput}/>
                            <br/>
                            <br/>
                        </div>

                        {invalidData && <div>{errorMessage}</div>}

                        {internalError && <div>{LOCALE.internal_error}</div>}

                        <button className={registeringUser ? "login-btn-disable btn1" : "btn1"}
                                onClick={this.userRegistration}>{registeringUser ? "" : LOCALE.register}</button>
                        {registeringUser && <div className="lds-dual-ring"/>}
                        <p> {LOCALE.already_have_account}<button className="btn-clear" onClick={() => {
                            history.goBack();
                            history.goBack();
                            history.goBack();
                        }}><strong>{LOCALE.login}</strong></button></p>
                    </div>

                </div>
            </div>

        );
    }
}

const actionCreators = {
    userRegistration: registrationAction.userRegistration
};

const mapState = (state) => {
    return {
        otpVerifyRegisterReducer: state.otpVerifyRegisterReducer,
        userRegisterReducer: state.userRegisterReducer
    }
};

export default connect(mapState, actionCreators)(Registration);
