import React, {Component} from 'react';
import {utils} from "../../helper/utils";
import Checkout from "./Checkout";
import {membershipPlansActions} from "../../actions/membershipPlansActions";
import {connect} from "react-redux";
import {history} from "../app/App";
import Modal from "react-modal";
import Loader from "../Loader/Loader";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class MembershipPlansDetails extends Component {
    componentDidMount() {
        const {membershipIds} = this.props.location.state;
        this.props.getMembershipPlanDetails(membershipIds);
    }


    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {gettingDetails, responseData, invalidData, internalError, errorMessage} = this.props.membershipPlanDetailsReducer;
        const {purchasingPlan, invalidDataPlan, internalErrorPlan, errorMessagePlan, showModal, purchaseResponseData} = this.props.purchaseMembershipPlansReducer;
        if (Object.entries(responseData).length === 0) {
            return (
                <div className="home-page-wrapper">
                    <div className="home-page">
                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{LOCALE.getting_plan_details}</h3>
                        </div>
                        <Loader/>

                    </div>
                </div>
            )

        } else {
            return (
                <div className="home-page-wrapper membership-details-page">
                    <div className="home-page">
                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{responseData.name}</h3>
                        </div>
                        <div className="homepage-content">
                            <div className="membership-details">
                                {showModal && <div>
                                    <Modal
                                        isOpen={true}
                                        contentLabel="Minimal Modal Example"
                                        ariaHideApp={false}

                                    >
                                        {purchaseResponseData ? <div>
                                            <h3>{LOCALE.payment_successful}</h3>
                                            <img src={require('../../img/tick.png')} alt="done"/>
                                            <button onClick={() => {
                                                window.location.reload();
                                            }}>{LOCALE.done}
                                            </button>
                                            <button onClick={() => {
                                                history.goBack();
                                            }}>{LOCALE.home}
                                            </button>
                                        </div> : <div>
                                            {purchasingPlan && <Loader/>}
                                            {internalErrorPlan && <div>Internal Error <br/>
                                                <button onClick={() => {
                                                    window.location.reload();
                                                }}>{LOCALE.done}
                                                </button>
                                                <button onClick={() => {
                                                    history.goBack();
                                                }}>{LOCALE.home}
                                                </button>
                                            </div>}
                                            {invalidDataPlan && <div>{errorMessagePlan}<br/>
                                                <button onClick={() => {
                                                    window.location.reload();
                                                }}>{LOCALE.done}
                                                </button>
                                                <button onClick={() => {
                                                    history.goBack();
                                                }}>{LOCALE.home}
                                                </button>
                                            </div>}
                                        </div>}


                                    </Modal>
                                </div>}

                                {gettingDetails && <Loader/>}
                                {internalError && <div>{LOCALE.internal_error}</div>}
                                {invalidData && <div>{errorMessage}</div>}
                                <div className='scrolly'>
                                    {
                                        <div className="membership-details2">
                                            <label className="plan-name">{`${responseData.description}`}</label>
                                            <img
                                                className='img-slide' src={responseData.image}
                                                alt='user'/>
                                            <label className="plan-price">{`$${responseData.price}`}</label>
                                            <label
                                                className="plan-time">{`per ${utils.getDuration(responseData.no_of_days)}`}</label>
                                            {/*<label>{`${membershipPlan.no_of_days} Days`}</label>*/}
                                            <br/>
                                            <div className="membership-plan-additional">
                                                {responseData.premium_access_feature.map((accessFeature, i) => (
                                                    <div key={i}>
                                                        <ul type="disc">
                                                            <li>{`${accessFeature}`}</li>
                                                        </ul>
                                                    </div>
                                                ))

                                                }
                                            </div>

                                            {parseInt(responseData.purchase_status) === 0 ?
                                                <Checkout
                                                    name={responseData.name}
                                                    description={responseData.description}
                                                    amount={responseData.price}
                                                    image={responseData.image}
                                                    id={responseData.membership_id}
                                                /> :
                                                <div className="subscribed-btn">
                                                    <button disabled={true}>
                                                        <span>&#10003;</span> {LOCALE.subscribed}
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapState = (state) => {
    return {
        membershipPlanDetailsReducer: state.getMembershipPlanDetailsReducer,
        purchaseMembershipPlansReducer: state.purchaseMembershipPlansReducer
    }
};
const actionCreators = {
    getMembershipPlanDetails: membershipPlansActions.getMembershipPlanDetails
};
export default connect(mapState, actionCreators)(MembershipPlansDetails)
