import { apiService } from "../services/apiService";
import { history } from "../components/app/App";
import { accoutSettingsConstants } from "../constants/accoutSettingsConstants";

export const accountSettingsAction = {
  changeEmail,
  verifyEmail,
  changePassword,
};

function changeEmail(userEmail) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .changeEmail(userEmail)
      .then((response) => {
        console.log("change email", response.data.data.email);
        // const insertId = response.data.insert_id;
        const insertId = response.data.data.email;
        const responseMessage = response.data.message;
        dispatch(success(response.data));
        history.push("/verifyEmail", {
          insertId: insertId,
          responseMessage: responseMessage,
        });
      })

      .catch((error) => {
        console.log("requestOtpRegistration error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: accoutSettingsConstants.CHANGE_EMAIL_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: accoutSettingsConstants.CHANGE_EMAIL_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: accoutSettingsConstants.CHANGE_EMAIL_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function verifyEmail(insertId, code) {
  console.log("in here verify", this.state);
  return (dispatch) => {
    dispatch(request());
    apiService
      .verifyEmail(insertId, code)
      .then((response) => {
        dispatch(success(response.data));
        history.goBack();
        history.goBack();
        history.goBack();
      })

      .catch((error) => {
        console.log("requestOtpRegistration error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: accoutSettingsConstants.VERIFY_EMAIL_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: accoutSettingsConstants.VERIFY_EMAIL_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: accoutSettingsConstants.VERIFY_EMAIL_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function changePassword(oldPassword, newPassword) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .changePassword(oldPassword, newPassword)
      .then((response) => {
        dispatch(success(response.data));
        history.goBack();
        history.goBack();
      })

      .catch((error) => {
        console.log("changePassword error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: accoutSettingsConstants.CHANGE_PASSWORD_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: accoutSettingsConstants.CHANGE_PASSWORD_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: accoutSettingsConstants.CHANGE_PASSWORD_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
