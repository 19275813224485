import {notificationConstants} from "../constants/notificationConstants";

export const notificationActions = {excludeNotification};

function excludeNotification(notificationType, userId) {
    return dispatch => {
        dispatch(success(notificationType, userId));
    };

    function success(type, userId) {
        return {
            type: notificationConstants.EXCLUDE_NOTIFICATION,
            notificationType,
            userId
        };
    }
}
