import React, { Component } from "react";
import { giftActions } from "../../actions/giftActions";
import { connect } from "react-redux";
import { history } from "../app/App.js";
import { utils } from "../../helper/utils.js";
import Loader from "../Loader/Loader.js";
import { default as LOCALE } from "../../localization/index.js";
import { userConstants } from "../../constants/userConstants.js";
import { giftsConstants } from "../../constants/giftsConstants";

import Tab from "../tab/tab.js";
import SideDrawer from "../SideDrawer/SideDrawer.js";

class ViewGifts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      received: true,
      sent: false,
    };
  }

  selectSent = () => {
    this.setState({ sent: true, received: false });
  };
  selectReceived = () => {
    this.setState({ sent: false, received: true });
  };

  componentDidMount() {
    this.props.getSentGiftsList();
    this.props.getReceivedGiftsList();
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };

  // drawerToggleClickHandler = () => {
  //   this.setState((prevState) => {
  //     return { sideDrawerOpen: !prevState.sideDrawerOpen };
  //   });
  // };

  // dismissSideDrawer = () => {
  //   this.setState({ sideDrawerOpen: false });
  // };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    console.log("gift props", this.props);
    const {
      gettingGifts,
      sentgifts,
      receivedgifts,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.getSentGiftsListReducer;

    console.log("searchResults render sentgifts=", sentgifts);
    console.log("searchResults render receivedgifts=", receivedgifts);

    if (sentgifts?.length > 0 || receivedgifts?.length > 0) {
      return (
        <div>
          <div className="home-page-wrapper search-result">
            <div className="home-page">
              <div className="top-bar">
                <img
                  onClick={() => {
                    history.goBack();
                  }}
                  src={require("../../img/back.png")}
                  alt="back-button"
                />
                <h3>{LOCALE.gifts}</h3>
              </div>

              <div className="friend-tab gift-tabs">
                <span
                  className={this.state.received ? "selected-friend-tab" : ""}
                  onClick={this.selectReceived}
                >
                  <p>{LOCALE.received_gifts}</p>
                </span>
                <span
                  className={this.state.sent ? "selected-friend-tab" : ""}
                  onClick={this.selectSent}
                >
                  <p>{LOCALE.sent_gifts}</p>
                </span>
              </div>

              {this.state.sent && (
                <div
                  className="gift-content scrollx"
                  onScroll={this.paginateIfNecessary}
                >
                  {sentgifts?.map((gift, i) => (
                    <div
                      onClick={this.handleImageClick.bind(this, gift.member_id)}
                      key={i}
                    >
                      <div className="searched-image">
                        <div className="searched-img">
                          <img
                            key={i}
                            className="img-search gift-img"
                            src={gift.gift_image}
                            alt="user"
                          />
                        </div>
                        <div className="searched-det gift-box">
                          <div className="gift-text">
                            <p>You have sent a gift to </p>
                            <p
                              className="gift-name"
                              onClick={this.handleImageClick.bind(
                                this,
                                gift.member_id
                              )}
                              key={i}
                            >{` ${gift.name}`}</p>
                          </div>
                          <br />
                          {/* <label>{gift.user_country}, </label>
                      {gift.user_state_name !== "None" ? (
                        <label>{gift.user_state}, </label>
                      ) : (
                        ""
                      )}
                      <label>{gift.user_city}</label> */}
                        </div>
                        <div class="line"></div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {this.state.received && (
                <div
                  className="scrollx gift-content"
                  onScroll={this.paginateIfNecessary}
                >
                  {receivedgifts?.map((gift, i) => (
                    <div
                      onClick={this.handleImageClick.bind(this, gift.member_id)}
                      key={i}
                    >
                      <div className="searched-image">
                        <div className="searched-img">
                          <img
                            key={i}
                            className="img-search gift-img"
                            src={gift.gift_image}
                            alt="user"
                          />
                        </div>
                        <div className="searched-det gift-box">
                          <div className="gift-text">
                            <p>You received a gift from </p>
                            <p
                              className="gift-name"
                              onClick={this.handleImageClick.bind(
                                this,
                                gift.member_id
                              )}
                              key={i}
                            >{` ${gift.name}`}</p>
                          </div>
                          <br />
                          {/* <label>{gift.user_country}, </label>
                      {gift.user_state_name !== "None" ? (
                        <label>{gift.user_state}, </label>
                      ) : (
                        ""
                      )}
                      <label>{gift.user_city}</label> */}
                        </div>
                        <div class="line"></div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {gettingGifts && <Loader />}

              {/* <div>
                Back to Profile?
                <button
                  className="btn-clear top-space"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <strong>Click here</strong>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      );
    }
    if (gettingGifts) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.gifts}</h3>
            </div>
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper friends-list">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.gifts}</h3>
            </div>
            <div className="homepage-content">
              <h2>{LOCALE.no_matches_yet}</h2>
              {gettingGifts && <Loader />}
              {internalError && <div>{LOCALE.internal_error}</div>}
              {invalidData && <div>{errorMessage}</div>}
              {/* <button
                className="btn00"
                onClick={() => {
                  history.push("/search");
                }}
              >
                {" "}
                {LOCALE.searchGifts}
              </button> */}
              {/*
                            <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    getSentGiftsListReducer: state.getSentGiftsListReducer,
    getReceivedGiftsListReducer: state.getReceivedGiftsListReducer,
  };
};
const actionCreators = {
  getReceivedGiftsList: giftActions.getReceivedGiftsList,
  getSentGiftsList: giftActions.getSentGiftsList,
};
export default connect(mapState, actionCreators)(ViewGifts);
