import { apiService } from "../services/apiService";
import { userConstants } from "../constants/userConstants";
import { utils } from "../helper/utils";
import { history } from "../components/app/App";

export const userActions = {
  getTrendingUsers,
  nextUsers,
  respondUser,
  getSearchUsers,
  reportUser,
  nearMeUsers,
  nearMeUsersSettings,
  getNearMeUsersSettings,
};

function getTrendingUsers() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getTrendingUsers()
      .then((response) => {
        console.log("getTrendingUsers response=", response.data);
        dispatch(success(response.data));
      })
      .catch((error) => {
        console.log("getTrendingUsers error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: userConstants.GET_TRENDING_USERS_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: userConstants.GET_TRENDING_USERS_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.GET_TRENDING_USERS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function nextUsers(next_page = 1) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getUsers(next_page)
      .then((response) => {
        console.log("getUsers response=", response.data);
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //   response.data.data = users;
        //   dispatch(success(response.data));
        // });

        var users = response.data.data;
        dispatch(success(response.data));
      })
      .catch((error) => {
        console.log("getUsers error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: userConstants.GET_USERS_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: userConstants.GET_USERS_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.GET_USERS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function respondUser(userId, status) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .respondUser(userId, status)
      .then((response) => {
        console.log("respondUser response=", response.data);
        dispatch(success(response.data));
      })
      .catch((error) => {
        console.log("respondUser error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: userConstants.RESPOND_USER_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: userConstants.RESPOND_USER_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.RESPOND_USER_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getSearchUsers(searchParams, searchType, page = 1) {
  console.log("getSearchUsers response=", searchParams, searchType);

  return (dispatch) => {
    dispatch(request(page === 1, searchParams, searchType));
    apiService
      .getSearchUsers(searchParams, searchType, page)
      .then((response) => {
        console.log("getSearchUsers response=", response.data);
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        const users = response.data.data;
        dispatch(success(response.data, page === 1));
        // });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset, searchParams, searchType) {
    return {
      type: userConstants.SEARCH_USER_REQUEST,
      reset,
      searchParams,
      searchType,
    };
  }

  function success(responseData, reset) {
    return {
      type: userConstants.SEARCH_USER_SUCCESS,
      responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.SEARCH_USER_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function reportUser(reportMessage, userId) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .reportUser(reportMessage, userId)
      .then((response) => {
        console.log("report user response=", response.data);
        dispatch(success(response.data));
        history.goBack();
      })
      .catch((error) => {
        console.log("reportUser error=", error);
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: userConstants.REPORT_USER_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: userConstants.REPORT_USER_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.REPORT_USER_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function nearMeUsers(lat, lng, page = 1) {
  return (dispatch) => {
    dispatch(request(page === 1));
    apiService
      .getNearMe(lat, lng, page)
      .then((response) => {
        dispatch(success(response.data, page === 1));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: userConstants.NEAR_ME_USER_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: userConstants.NEAR_ME_USER_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.NEAR_ME_USER_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function nearMeUsersSettings(unit, value) {
  return (dispatch) => {
    dispatch(request());
    apiService
      .updateNearMeSettings(unit, value)
      .then((response) => {
        dispatch(success(response.data));
        history.goBack();
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: userConstants.NEAR_ME_USER_SETTINGS_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: userConstants.NEAR_ME_USER_SETTINGS_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.NEAR_ME_USER_SETTINGS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}

function getNearMeUsersSettings() {
  return (dispatch) => {
    dispatch(request());
    apiService
      .getNearMeSettings()
      .then((response) => {
        dispatch(success(response.data.data));
        history.push("/nearMeSettings", { initialData: response.data.data });
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request() {
    return {
      type: userConstants.GET_NEAR_ME_USER_SETTINGS_REQUEST,
    };
  }

  function success(responseData) {
    return {
      type: userConstants.GET_NEAR_ME_USER_SETTINGS_SUCCESS,
      responseData: responseData,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: userConstants.GET_NEAR_ME_USER_SETTINGS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
