import {notificationConstants} from "../constants/notificationConstants";

const initialState = {
    messages: [],
    excludeFrom: '',
    timeout: false
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case notificationConstants.NOTIFICATION_TYPE_MESSAGE: {
            const {userId, name, chatText, deviceMessageId, updatedAt} = action.payload;

            const message = {userId, name, chatText, deviceMessageId, updatedAt};

            if (userId === state.excludeFrom) {
                return state;
            }
            return {
                ...state,
                messages: [...state.messages, message],
                message,
                timeout: false
            }
        }
        case notificationConstants.EXCLUDE_NOTIFICATION: {
            switch (action.notificationType) {
                case 'message':
                    let {messages} = state;
                    messages = messages.filter(message => message.userId !== action.userId);
                    return {
                        ...state,
                        excludeFrom: action.userId,
                        messages
                    };
                default:
                    return state;
            }
        }
        case notificationConstants.ON_TIMEOUT: {
            return {...state, timeout: true}
        }
        default:
            return state;
    }
};

export {
    notificationReducer
}
