import React, { Component } from "react";
import ImageCrop from "../register/imageCrop";
import "../../css/style.css";
import { connect } from "react-redux";
import { helperActions } from "../../actions/helperActions";
import { countryActions } from "../../actions/countryActions";
import { photoActions } from "../../actions/photoActions";
import { history } from "../app/App";
import { utils } from "../../helper/utils";
import { profileActions } from "../../actions/profileActions";
import { loginActions } from "../../actions/loginActions";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import { countryConstants } from "../../constants/countryConstants";
import { useSelector } from "react-redux";

class UpdateProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image_path: {},
      image: {},
      seeking: "M",
      countryIndex: 0,
      stateIndex: 0,
      cityIndex: 0,
      noImageSelected: false,
      gender: "",
      age: "",
      genderEmpty: false,
      dobEmpty: false,
      validDob: true,
      countryList: null,
      stateList: null,
      cityList: null,
      image: null,
      birthday: null,
    };

    this.aboutMeTextAreaRef = React.createRef();
    this.myInterestTextAreaRef = React.createRef();
    this.props.getCountryList();
    this.props.getStateList();
    this.props.getCityList();
  }

  // populateCountryList = () => {
  //   const { countryList } = this.props;

  //   let items = [];
  //   for (let i = 0; i < countryList.length; i++) {
  //     items.push(
  //       <option key={i} value={i}>
  //         {countryList[i]}
  //       </option>
  //     );
  //   }
  //   return 0;
  //   // const countryList = this.props.getCountryList();

  //   // return countryList.responseData.data;
  // };

  // populateStateList = () => {
  //   const { stateList } = this.props.countryReducer;

  //   let items = [];
  //   for (let i = 0; i < stateList.length; i++) {
  //     items.push(
  //       <option key={i} value={i}>
  //         {stateList[i]}
  //       </option>
  //     );
  //   }
  //   return items;
  // };

  // populateCityList = () => {
  //   const { cityList } = this.props.countryReducer;
  //   let items = [];
  //   for (let i = 0; i < cityList.length; i++) {
  //     items.push(
  //       <option key={i} value={i}>
  //         {cityList[i]}
  //       </option>
  //     );
  //   }
  //   return items;
  // };

  loadInitialData = async (initialData) => {
    if (!initialData) {
      return;
    }

    const {
      image_path: image,
      user_gender_enum: gender,
      user_dob: age,
      user_seeking_enum: seeking,
      user_country_id: countryIndex,
      user_state_id: stateIndex,
      user_city_id: cityIndex,
      user_about_me: about_me,
      user_my_interest: my_interest,
      profile_question_details: profile_questions,
    } = initialData;

    // const { countryIndex, stateIndex, cityIndex } =
    //   await utils.getCountryStateCityIndices(
    //     initialData.country_id,
    //     initialData.state_id,
    //     initialData.city_id
    //   );

    this.setState({
      image: image,
      age: age,
      gender: gender,
      seeking: seeking,
      countryIndex: countryIndex,
      stateIndex: stateIndex,
      cityIndex: cityIndex,
      about_me: about_me,
      my_interest: my_interest,
    });
  };

  async componentDidMount() {
    const { createNew, initialData } = this.props.location.state;

    const stateLists = this.props?.getStateList(
      initialData?.data?.user_country_id
    );
    this.props.getCityList(
      initialData?.data?.user_country_id,
      initialData?.data?.user_state_id
    );
    this.props.getStateList(initialData?.data?.user_country_id);

    this.setState({
      countryIndex: initialData?.data?.user_country_id,
      stateIndex: initialData?.data?.user_state_id,
      cityIndex: initialData?.data?.user_city_id,
      stateList: 0,
    });

    if (!createNew && initialData) {
      await this.loadInitialData(initialData.data);
    }

    // const { countryIndex, stateIndex } = this.state;
  }

  async componentDidUpdate(previousProps, previousState, countryList) {
    if (
      previousProps?.countryReducer?.responseData !=
      this.props?.countryReducer?.responseData
    ) {
      this.setState({
        countryList: this.props?.countryReducer?.responseData?.responseData,
        stateList: this.props?.stateReducer?.responseData,
        cityList: this.props?.cityReducer?.responseData,
      });
    }
  }

  onInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSeekingSelected = (value) => {
    this.setState({ seeking: value });
  };

  onCountrySelected = (selectedCountryIndex) => {
    // console.log("selected country", selectedCountryIndex);
    // this.props.loadStateCityList(selectedCountryIndex);
    const stateLists = this.props?.getStateList(selectedCountryIndex);

    this.setState({
      countryIndex: selectedCountryIndex,
      stateIndex: 0,
      cityIndex: 0,
      stateList: 0,
    });
  };

  onStateSelected = (selectedStateIndex) => {
    const { countryIndex } = this.state;

    const stateLists = this.props?.getCityList(
      countryIndex,
      selectedStateIndex
    );

    this.props.getCityList(countryIndex, selectedStateIndex);
    this.setState({ stateIndex: selectedStateIndex, cityIndex: 0 });
  };

  onCitySelected = (selectedCityIndex) => {
    this.setState({ cityIndex: selectedCityIndex });
  };

  onImageSelected = (image_orig, image_350, image_250) => {
    this.setState({ image: { image_orig, image_350, image_250 } });
  };

  isFieldValid = (fieldValue) => {
    return fieldValue?.toString().length > 0;
  };

  maxDate = () => {
    let maxDate = Date.parse(new Date()) - 568025136000;
    let date = new Date(maxDate);

    let dd = date.getDate();
    let mm = date.getMonth() + 1; //January is 0!
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = yyyy + "-" + mm + "-" + dd;

    return date;
  };

  isValidDOB = (dob) => {
    //24*60*60*1000*365.25=31557600000
    let age = utils.getAge(dob);

    return age < 18;
  };

  updateProfile = async () => {
    const { image, seeking, countryIndex, stateIndex, cityIndex, gender, age } =
      this.state;
    const { createNew } = this.props.location.state;

    if (!image && createNew) {
      this.setState({ noImageSelected: true });
      return;
    }
    this.setState({ noImageSelected: false });

    if (!this.isFieldValid(gender)) {
      this.setState({ genderEmpty: true });
      return;
    }
    if (!this.isFieldValid(seeking)) {
      this.setState({ seeking: "M" });
      return;
    }
    this.setState({ genderEmpty: false });

    if (!this.isFieldValid(age)) {
      this.setState({ dobEmpty: true });
      return;
    }
    this.setState({ dobEmpty: false });

    if (this.isValidDOB(age)) {
      this.setState({ validDob: false });
      return;
    }
    this.setState({ validDob: true });

    // const { countryId, stateId, cityId } = await utils.getCountryStateCityIds(
    //   countryIndex,
    //   stateIndex,
    //   cityIndex
    // );
    const { countryId, stateId, cityId } = {
      countryIndex,
      stateIndex,
      cityIndex,
    };

    //img conversion

    if (typeof this.state.image.image_orig != "string") {
      var imgOrig;
      var img250;
      var img350;
      var check = false;

      const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      };

      blobToBase64(this.state.image.image_orig).then((res) => {
        imgOrig = res.replace(/^data:image\/[a-z]+;base64,/, "");
      });
      blobToBase64(this.state.image.image_250).then((res) => {
        img250 = res.replace(/^data:image\/[a-z]+;base64,/, "");
      });
      blobToBase64(this.state.image.image_350).then((res) => {
        img350 = res.replace(/^data:image\/[a-z]+;base64,/, "");
        var imgObj = {
          img_orig: imgOrig,
          img_250: img250,
          img_350: img350,
        };
        this.props.updatePhoto(imgObj);
      });
    }

    // var imgObj = {
    //   img_orig: imgOrig,
    //   img_250: img250,
    //   img_350: img350,
    // };

    // if (check) {
    //   console.log("updatePhoto called 3", this.state.image)

    //   console.log("images object", imgObj);
    //   this.props.updatePhoto(imgObj);
    // }
    this.props.updateProfile(
      image,
      gender,
      age,
      seeking,
      countryIndex,
      stateIndex,
      cityIndex,
      this.aboutMeTextAreaRef.current.value,
      this.myInterestTextAreaRef.current.value,
      createNew
    );
  };

  updatePhoto = async () => {
    const { image } = this.state;

    this.props.updatePhoto(image);
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      countryIndex,
      stateIndex,
      cityIndex,
      image,
      seeking,
      noImageSelected,
      validDob,
      dobEmpty,
      genderEmpty,
      gender,
      age,
    } = this.state;
    const { updatingProfile, invalidData, internalError, errorMessage } =
      this.props.profileReducer;
    const { initialData, includesLogout } = this.props.location.state;
    const { loggingOut } = this.props.logoutReducer;
    const countriesList =
      this.props?.countryReducer?.responseData?.responseData;
    const stateList = this.props?.stateReducer?.responseData;
    const cityList = this.props?.cityReducer?.responseData;

    // countriesList?.data?.name
    let image_orig, about_me, my_interest;
    if (initialData) {
      image_orig = initialData?.data?.image_path?.image_orig;
      about_me = initialData?.data?.user_about_me;
      my_interest = initialData?.data?.user_my_interest;
    }

    return (
      <div className="home-page-wrapper edit-profile">
        <div className="home-page">
          <div className="top-bar">
            <img
              onClick={() => {
                history.goBack();
              }}
              src={require("../../img/back.png")}
              alt="back-button"
            />
            <h3>{LOCALE.edit_profile}</h3>
          </div>
          <div className="homepage-content">
            <div className="upload-image">
              <ImageCrop
                defaultImage={image_orig}
                onImageSelected={this.onImageSelected}
              />

              {noImageSelected && <div>{LOCALE.please_select_the_image}</div>}
            </div>
            <br />
            <div className="edit-profile-width">
              <div className="text-left">
                {genderEmpty && <div>{LOCALE.gender_required}</div>}
                <label className="text-left">{LOCALE.gender}</label>
                <label className="radio-btn">
                  {" "}
                  {LOCALE.male}{" "}
                  <input
                    type="radio"
                    name="gender"
                    value="M"
                    checked={gender === "M"}
                    onChange={this.onInput}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="radio-btn">
                  {" "}
                  {LOCALE.female}{" "}
                  <input
                    type="radio"
                    name="gender"
                    value="F"
                    checked={gender === "F"}
                    onChange={this.onInput}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="radio-btn">
                  {" "}
                  {LOCALE.couples}{" "}
                  <input
                    type="radio"
                    name="gender"
                    value="C"
                    checked={gender === "C"}
                    onChange={this.onInput}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

              {dobEmpty && <div>{LOCALE.dob_required}</div>}
              {!validDob && <div>{LOCALE.must_be_18}</div>}

              <div className="edit-profile-dob">
                <label className="text-left"> {LOCALE.birthday}</label>
                <input
                  className={
                    !validDob ? "input-field input-field-error" : "input-field"
                  }
                  type="date"
                  name="age"
                  value={age}
                  max={this.maxDate()}
                  onChange={this.onInput}
                />
              </div>

              <label className="text-left">{LOCALE.seeking}</label>
              <div id="basic-select">
                <select
                  className="input-field"
                  value={seeking}
                  onChange={(e) => {
                    this.onSeekingSelected(e.target.value);
                  }}
                >
                  <option value="M">{LOCALE.male}</option>
                  <option value="F">{LOCALE.female}</option>
                  <option value="C">{LOCALE.couples}</option>
                </select>
              </div>

              <label className="text-left">
                {LOCALE.country}
                <span>*</span>
              </label>

              <div id="basic-select">
                <select
                  className="input-field"
                  // value={countriesList?.data}
                  onChange={(e) => {
                    this.onCountrySelected(e.target.value);
                  }}
                >
                  <option selected>{initialData?.data?.user_country}</option>
                  {countriesList?.data.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  {/* {countriesList?.data} */}
                </select>
              </div>

              <label className="text-left">
                {LOCALE.state}
                <span>*</span>
              </label>
              <div id="basic-select">
                <select
                  className="input-field"
                  // value={stateIndex}
                  onChange={(e) => {
                    this.onStateSelected(e.target.value);
                  }}
                >
                  <option selected>{initialData?.data?.user_state}</option>
                  {stateList?.data.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  {/* {this.populateStateList()} */}
                </select>
              </div>

              <label className="text-left">
                {LOCALE.city}
                {/* <span>*</span> */}
              </label>
              <div id="basic-select">
                <select
                  className="input-field"
                  // value={cityIndex}
                  onChange={(e) => {
                    this.onCitySelected(e.target.value);
                  }}
                >
                  <option selected>{initialData?.data?.user_city}</option>
                  {cityList?.data.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  {/* {this.populateCityList()} */}
                </select>
              </div>

              <label className="text-left">{LOCALE.about_me}</label>
              <textarea
                className="input-field textarea"
                defaultValue={about_me}
                ref={this.aboutMeTextAreaRef}
              />

              <label className="text-left">{LOCALE.my_interests}</label>
              <textarea
                className="input-field textarea"
                defaultValue={my_interest}
                ref={this.myInterestTextAreaRef}
              />
              {invalidData && <div>{errorMessage}</div>}
              {internalError && <div>Internal Error</div>}
              <button className="btn1" onClick={this.updateProfile}>
                {updatingProfile ? LOCALE.updating_profile : LOCALE.saveprofile}
              </button>
              {includesLogout && (
                <button className="btn01" onClick={() => this.props.logout()}>
                  {loggingOut ? LOCALE.logging_out : LOCALE.logout}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    countryReducer: state.getCountryReducer,
    stateReducer: state.getStateReducer,
    cityReducer: state.getCityReducer,
    profileReducer: state.profileReducer,
    logoutReducer: state.logoutReducer,
    photoReducer: state.photoReducer,
  };
};

const actionCreators = {
  // loadCountryStateCityList: helperActions.loadCountryStateCityList,
  // loadStateCityList: helperActions.loadStateCityList,
  // loadCityList: helperActions.loadCityList,

  updateProfile: profileActions.updateProfile,
  logout: loginActions.logout,
  getCountryList: countryActions.getCountries,
  getStateList: countryActions.getStates,
  getCityList: countryActions.getCities,
  updatePhoto: photoActions.updatePhoto,
};

export default connect(mapState, actionCreators)(UpdateProfile);
