import { apiService } from "../services/apiService";
import { utils } from "../helper/utils";
import { matchesConstants } from "../constants/matchesConstants";
import firebase from "firebase";
import "firebase/database";

export const matchActions = {
  getMatchesList,
};

function getMatchesList(page = 1) {
  return (dispatch) => {
    dispatch(request(page === 1));
    apiService
      .getMatchesList(page)
      .then((response) => {
        console.log("getmatches response=", page);
        // utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
        //     response.data.data = users;
        //     dispatch(success(response.data, page === 1));
        // });
        var users = response.data.data;
        dispatch(success(response.data.data, page === 1));
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.status &&
          error.response.data.message
        ) {
          dispatch(failure(error.response.status, error.response.data.message));
        } else {
          dispatch(failure(-1));
        }
      });
  };

  function request(reset) {
    return {
      type: matchesConstants.FETCH_MATCHES_LISTS_REQUEST,
      reset,
    };
  }

  function success(responseData, reset) {
    return {
      type: matchesConstants.FETCH_MATCHES_LISTS_SUCCESS,
      responseData: responseData,
      reset,
    };
  }

  function failure(errorCode, errorMessage) {
    return {
      type: matchesConstants.FETCH_MATCHES_LISTS_FAILURE,
      errorCode: errorCode,
      errorMessage: errorMessage,
    };
  }
}
