export const winksConstants = {
  // FETCH_SENT_VIEWS_LISTS_REQUEST: "FETCH_SENT_VIEWS_LISTS_REQUEST",
  // FETCH_SENT_VIEWS_LISTS_SUCCESS: "FETCH_SENT_VIEWS_LISTS_SUCCESS",
  // FETCH_SENT_VIEWS_LISTS_FAILURE: "FETCH_SENT_VIEWS_LISTS_FAILURE",

  FETCH_RECEIVED_WINKS_LISTS_REQUEST: "FETCH_RECEIVED_WINKS_LISTS_REQUEST",
  FETCH_RECEIVED_WINKS_LISTS_SUCCESS: "FETCH_RECEIVED_WINKS_LISTS_SUCCESS",
  FETCH_RECEIVED_WINKS_LISTS_FAILURE: "FETCH_RECEIVED_WINKS_LISTS_FAILURE",

  FETCH_ALL_FLIRTS_REQUEST: "FETCH_ALL_FLIRTS_REQUEST",
  FETCH_ALL_FLIRTS_SUCCESS: "FETCH_ALL_FLIRTS_SUCCESS",
  FETCH_ALL_FLIRTS_FAILURE: "FETCH_ALL_FLIRTS_FAILURE",

  SEND_WINK_REQUEST: "SEND_WINK_REQUEST",
  SEND_WINK_SUCCESS: "SEND_WINK_SUCCESS",
  SEND_WINK_FAILURE: "SEND_WINK_FAILURE",

  // RESPOND_GIFT_REQUEST: "RESPOND_GIFT_REQUEST",
  // RESPOND_GIFT_SUCCESS: "RESPOND_GIFT_SUCCESS",
  // RESPOND_GIFT_FAILURE: "RESPOND_GIFT_FAILURE",
};
