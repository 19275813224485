import React, {Component} from 'react';
import {connect} from "react-redux";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class Notification extends Component {

    formatSenderNames = (messages = []) => {
        const names = [];
        console.log('formatSenderNames messages=', messages);

        messages.forEach(message => {
            if (!names.includes(message.name)) {
                names.push(message.name);
            }
        });

        let text = `You have received ${messages.length} new message from `;

        if (names.length === 0) {
            text = '';
        } else if (names.length === 1) {
            text += names[0];
        } else if (names.length === 2) {
            text += `${names[0]} and ${names[1]}`
        } else {
            text += `${names[0]}, ${names[1]} and ${names.length - 2} others`
        }

        return text;
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {messages, message, timeout} = this.props.notificationReducer;

        console.log('notification messages=', messages);

        const {type, showLastOnly} = this.props;

        if (type === 'message' && !timeout && message && showLastOnly) {
            const {userId, name} = message;

            return (
                <div id="chat-notification"
                     onClick={() => {
                         this.props.onClick(type, {userId})
                     }}>
                    {LOCALE.new_message_from} {name}
                </div>);
        } else {
            return <div/>;
        }
    }
}


const mapState = (state) => {
    return {
        notificationReducer: state.notificationReducer
    }
};

export default connect(mapState, null)(Notification);
