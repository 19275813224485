import React, { Component } from "react";
import { friendActions } from "../../actions/friendActions";
import { history } from "../app/App";
import { utils } from "../../helper/utils";
import { connect } from "react-redux";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import { friendsConstants } from "../../constants/friendsConstants";
import Modal from "react-modal";

class PendingFriendRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAcceptModal: false,
      showRejectModal: false,
      friend: null,
    };
  }
  componentDidMount() {
    this.props.pendingFriendsRequestList();
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };

  handleAcceptCloseModal = () => {
    this.setState({
      showAcceptModal: false,
    });
  };

  handleRejectCloseModal = () => {
    this.setState({
      showRejectModal: false,
    });
  };

  acceptFriendRequest = (id) => {
    this.setState({
      showAcceptModal: true,
      friend: id,
    });
  };

  confirmAccept = (id) => {
    console.log("cancel req", id);
    this.props.respondFriend(
      this.state.friend,
      friendsConstants.FRIEND_ACCEPT_REQUEST
    );
    this.setState({
      showAcceptModal: false,
    });
  };

  rejectFriendRequest = (id) => {
    this.setState({
      showRejectModal: true,
      friend: id,
    });
  };

  confirmReject = (id) => {
    console.log("cancel req", id);
    this.props.respondFriend(
      this.state.friend,
      friendsConstants.FRIEND_DECLINE_REQUEST
    );
    this.setState({
      showRejectModal: false,
    });
  };
  paginateIfNecessary = (e) => {
    const { gettingPendingRequests, lastPage, page } =
      this.props.pendingFriendRequestsReducer;
    console.log("paginateIfNecessary", {
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      clientHeight: e.target.clientHeight,
    });
    const bottom =
      Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) ===
      Math.round(e.target.clientHeight);

    console.log("paginateIfNecessary bottom", bottom);

    if (bottom) {
      if (lastPage || gettingPendingRequests) {
        gettingPendingRequests
          ? console.log("getting search users already")
          : console.log("last page reached");
      } else {
        console.log("getting more users", page);
        this.props.pendingFriendsRequestList(page + 1);
      }
    }
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      gettingPendingRequests,
      pendingFriendRequests,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.pendingFriendRequestsReducer;
    console.log("searchResults render users=", gettingPendingRequests);
    if (pendingFriendRequests.length > 0) {
      return (
        <div className="home-page-wrapper search-result">
          <div className="home-page">
            {/* <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.friend_requests}</h3>
            </div> */}

            <div className="scrollx" onScroll={this.paginateIfNecessary}>
              {pendingFriendRequests.map((pendingFriendRequest, i) => (
                <div
                  // onClick={this.handleImageClick.bind(
                  //   this,
                  //   pendingFriendRequest.user_id
                  // )}
                  key={i}
                >
                  <div className="searched-image">
                    <div className="searched-img">
                      <img
                        key={i}
                        className="img-search"
                        src={pendingFriendRequest.user_image_path.image_250}
                        alt="user"
                      />
                    </div>
                    <div className="searched-det">
                      <label>{`${pendingFriendRequest.user_display_name}`}</label>
                      <br />
                      {/* <label>{pendingFriendRequest.countryName}, </label>
                      {pendingFriendRequest.stateName !== "None" ? (
                        <label>{pendingFriendRequest.stateName}, </label>
                      ) : (
                        ""
                      )}
                      <label>{pendingFriendRequest.cityName}</label> */}
                      <br />
                      <br />
                    </div>
                    <div
                      onClick={(e) =>
                        this.acceptFriendRequest(pendingFriendRequest.friend_id)
                      }
                      className="respond-icons"
                    >
                      <span class="material-symbols-outlined">check</span>
                    </div>
                    <div
                      onClick={(e) =>
                        this.rejectFriendRequest(pendingFriendRequest.friend_id)
                      }
                      className="respond-icons-cancel"
                    >
                      <span class="material-symbols-outlined">close</span>
                    </div>
                    <div class="line"></div>
                  </div>
                </div>
              ))}
            </div>
            {gettingPendingRequests && <Loader />}
          </div>
          <Modal
            isOpen={this.state.showAcceptModal}
            contentLabel="Minimal Modal Example"
            ariaHideApp={false}
            onClose={this.handleAcceptCloseModal}
          >
            <img
              className="cancel-icon"
              onClick={this.handleAcceptCloseModal}
              src={require("../../img/cancel.png")}
              alt="cancel-button"
            />
            <h3>Accept Request</h3>

            <p>Are you sure you want to accept this request?</p>
            <button onClick={this.handleAcceptCloseModal}>{LOCALE.no}</button>
            <button onClick={this.confirmAccept}>{LOCALE.yes}</button>
          </Modal>
          <Modal
            isOpen={this.state.showRejectModal}
            contentLabel="Minimal Modal Example"
            ariaHideApp={false}
            onClose={this.handleRejectCloseModal}
          >
            <img
              className="cancel-icon"
              onClick={this.handleRejectCloseModal}
              src={require("../../img/cancel.png")}
              alt="cancel-button"
            />
            <h3>Reject Request</h3>

            <p>Are you sure you want to reject this request?</p>
            <button onClick={this.handleRejectCloseModal}>{LOCALE.no}</button>
            <button onClick={this.confirmReject}>{LOCALE.yes}</button>
          </Modal>
        </div>
      );
    } else if (gettingPendingRequests) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            {/* <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.friend_requests}</h3>
            </div> */}
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            {/* <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.friend_requests}</h3>
            </div> */}
            <div className="homepage-content">
              {internalError && <div>{LOCALE.internal_error}</div>}
              {invalidData && <div>{errorMessage}</div>}
              <h3> {LOCALE.no_pending_requests}</h3>

              {/*        <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    pendingFriendRequestsReducer: state.pendingFriendRequestsReducer,
    respondFriendReducer: state.respondFriendReducer,
    checkFriendStatusReducer: state.checkFriendStatusReducer,
  };
};

const actionCreators = {
  pendingFriendsRequestList: friendActions.pendingFriendsRequestList,
  respondFriend: friendActions.respondFriend,
  checkFriendStatus: friendActions.checkFriendStatus,
};

export default connect(mapState, actionCreators)(PendingFriendRequests);
