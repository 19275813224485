import React, { Component } from "react";

class SearchFields extends Component {
  constructor(props) {
    super(props);

    //initialization
    const {
      question_name,
      name,
      options,
      selected_question_option_ids,
      required,
    } = this.props.question;
    /*
                if (required === 'Y' && (!selected_question_option_ids || selected_question_option_ids.length === 0)) {
                    this.props.onInputError(profile_setup_id, true);
                    this.state = {inputError: true};
                } else {*/
    // }

    const answers = [];
    let selected_answers = [];

    //converting ids to indices
    const selected_question_option_indices = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      answers.push(option.option_value);

      if (selected_question_option_ids) {
        for (const key in selected_question_option_ids) {
          if (selected_question_option_ids.hasOwnProperty(key)) {
            const { answer } = selected_question_option_ids[key];
            for (const question_id of answer.options) {
              selected_answers.push(question_id.question_option_id);
            }
          }
        }

        if (selected_answers.includes(option.question_option_id)) {
          selected_question_option_indices.push(i);
        }
      }
    }

    this.question = {
      selected_question_option_indices,
      question_name,
      name,
      answers,
      required,
    };

    this.options = {};

    //populating initial answers
    options
      .filter(
        (option) =>
          selected_question_option_ids &&
          selected_answers.includes(option.question_option_id)
      )
      .forEach((option) => {
        this.options[option.question_option_id] = option;
      });
  }

  onAnswerChanged = (selectedIndex, checked) => {
    const { profile_setup_id, options } = this.props.question;
    const { question_option_id } = options[selectedIndex];
    const option = { question_option_id };
    if (checked) {
      this.options[question_option_id] = option;
    } else {
      delete this.options[question_option_id];
    }

    const optionsArray = Object.values(this.options);
    this.props.onAnswerChanged(profile_setup_id, {
      options: optionsArray,
    });
  };

  render() {
    const { question_name, name, answers, selected_question_option_indices } =
      this.question;

    return (
      <div className="question-multiple">
        <label>{name}</label>
        <div>
          {answers.map((answer, i) => (
            <div className="questions-1" key={i}>
              <input
                className="question-check"
                type="checkbox"
                name={answer}
                defaultChecked={selected_question_option_indices.includes(i)}
                onChange={(e) => {
                  this.onAnswerChanged(e.target.value, e.target.checked);
                }}
                value={i}
              />
              {answer}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SearchFields;
