import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../app/App";
import { utils } from "../../helper/utils";
import "../../css/style.css";
import { userActions } from "../../actions/userActions";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";

class SearchResults extends Component {
  componentDidMount() {
    const { searchParams, searchType } = this.props.location.state;

    this.props.getSearchUsers(searchParams, searchType);
    console.log("search type =", searchType);
  }

  handleImageClick = (userProfileId) => {
    history.push(`/users/${userProfileId}`);
  };

  paginateIfNecessary = (e) => {
    const { searchingUser, lastPage, page } = this.props.getSearchUserReducer;

    console.log("paginateIfNecessary", {
      scrollHeight: e.target.scrollHeight,
      scrollTop: e.target.scrollTop,
      clientHeight: e.target.clientHeight,
    });
    const bottom =
      Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) ===
      Math.round(e.target.clientHeight);

    console.log("paginateIfNecessary bottom", bottom);

    if (bottom) {
      if (lastPage || searchingUser) {
        searchingUser
          ? console.log("getting search users already")
          : console.log("last page reached");
      } else {
        const { searchParams, searchType } = this.props.location.state;
        console.log("getting more users", searchType);
        this.props.getSearchUsers(searchParams, searchType, page + 1);
      }
    }
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    console.log("searchResults users props=", this.props.getSearchUserReducer);

    console.log("searchResults users=", this.props.getSearchUserReducer);
    const { searchingUser, users, invalidData, internalError, errorMessage } =
      this.props.getSearchUserReducer;
    console.log("searchResults render users=", JSON.stringify(users.length));
    console.log("searchResults users=", this.props.getSearchUserReducer);

    if (users.length > 0) {
      return (
        <div className="home-page-wrapper search-result">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.search_results}</h3>
            </div>

            <div className="scrollx" onScroll={this.paginateIfNecessary}>
              {users.map((user, i) => (
                <div
                  className="searched-image"
                  onClick={this.handleImageClick.bind(this, user.id)}
                  key={i}
                >
                  <div className="searched-img">
                    <img
                      onClick={this.handleImageClick.bind(this, user.id)}
                      key={i}
                      src={user.image_path.image_250}
                      alt="user"
                    />
                  </div>
                  <div className="searched-det">
                    <label>{`${user.name}, ${user.user_age}`}</label>
                    <br />
                    <label>{user.user_country}, </label>
                    {user.stateName !== "None" ? (
                      <label>{user.user_state}, </label>
                    ) : (
                      ""
                    )}
                    <label>{user.user_city}</label>
                  </div>
                </div>
              ))}
            </div>
            {searchingUser && <Loader />}
          </div>
        </div>
      );
    } else if (searchingUser) {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.search_results}</h3>
            </div>
            <Loader />
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.search_results}</h3>
            </div>
            <div className="homepage-content">
              {internalError && <div>{LOCALE.internal_error}</div>}
              {invalidData && <div>{errorMessage}</div>}
              {!internalError && !invalidData && (
                <h3> {LOCALE.no_results_found}</h3>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    getSearchUserReducer: state.getSearchUserReducer,
  };
};

const actionCreators = {
  getSearchUsers: userActions.getSearchUsers,
};

export default connect(mapState, actionCreators)(SearchResults);
