import React from "react";
import "react-image-crop/dist/ReactCrop.css";
import "../../css/style.css";
import "../app/App.css";
import Cropper from "react-easy-crop";
import cropImage from "../../helper/cropImage";
import imageCompression from "browser-image-compression";

class ImageCrop extends React.Component {
  constructor(props) {
    super(props);

    this.squareSize = 290;

    this.state = {
      cropper: {
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1,
        cropSize: { width: this.squareSize, height: this.squareSize },
        rotation: 0,
      },
      cropping: false,
    };
  }

  componentDidMount() {
    console.log(this.props);
    if (this.props.defaultImage) {
      this.setState({
        cropper: {
          ...this.state.cropper,
          image: this.props.defaultImage,
        },
        cropping: false,
        imageProcessed: true,
      });

      console.log("image=", this.props.defaultImage);
    }

    console.log("componentDidMount() imageCrop=", this.props.defaultImage);
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.setState({
            cropper: { ...this.state.cropper, image: reader.result },
            cropping: true,
            imageProcessed: false,
          });
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onCropChange = (crop) => {
    this.setState({
      cropper: { ...this.state.cropper, crop },
    });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log("croppedArea=", croppedArea);
    console.log("croppedAreaPixels=", croppedAreaPixels);

    this.setState({
      cropper: { ...this.state.cropper, croppedAreaPixels },
    });
  };

  onZoomChange = (zoom) => {
    this.setState({
      cropper: { ...this.state.cropper, zoom },
    });
  };

  onImageLoaded = (imageSize) => {
    console.log("imageSize=", imageSize);
    let minZoom;
    if (imageSize.width < this.squareSize) {
      minZoom = this.squareSize / imageSize.width;
    } else {
      minZoom = this.squareSize / imageSize.height;
    }
    this.setState({
      cropper: { ...this.state.cropper, minZoom: minZoom, zoom: minZoom },
    });
  };

  rotateImage = () => {
    const newRot = (this.state.cropper.rotation + 90) % 360;
    this.setState({ cropper: { ...this.state.cropper, rotation: newRot } });
    console.log("state=", this.state);
  };

  compressImage = async (newImage) => {
    const options_image_orig = {
      maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: undefined, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
      maxIteration: 10, // optional, max number of iteration to compress the image (default: 10)
    };

    const options_image_350 = {
      maxSizeMB: 1,
      maxWidthOrHeight: 350,
      useWebWorker: true,
      maxIteration: 10,
    };
    const options_image_250 = {
      maxSizeMB: 1,
      maxWidthOrHeight: 250,
      luseWebWorker: true,
      maxIteration: 10,
    };

    const image_orig = await imageCompression(newImage, options_image_orig);
    const image_350 = await imageCompression(newImage, options_image_350);
    const image_250 = await imageCompression(newImage, options_image_250);

    return new Promise((resolve, reject) => {
      resolve({ image_orig, image_350, image_250 });
    });
  };

  cropImage = async () => {
    this.setState({ cropping: false });
    const { image, croppedAreaPixels, rotation } = this.state.cropper;
    const newImage = await cropImage(image, croppedAreaPixels, rotation);

    const { image_orig, image_350, image_250 } = await this.compressImage(
      newImage
    );
    console.log("processed images", image_orig, image_350, image_250);

    this.setState({
      cropper: {
        ...this.state.cropper,
        image: URL.createObjectURL(image_orig),
      },
      cropping: false,
      imageProcessed: true,
    });

    this.props.onImageSelected(image_orig, image_350, image_250);
  };

  goBack = () => {
    this.setState({
      cropper: { ...this.state.cropper, image: null },
      cropping: false,
    });
  };

  render() {
    const { cropper, cropping, imageProcessed } = this.state;
    return (
      <div className="crop-image">
        {cropping ? (
          <div className="crop-area">
            <Cropper
              image={this.state.cropper.image}
              crop={this.state.cropper.crop}
              zoom={this.state.cropper.zoom}
              aspect={this.state.cropper.aspect}
              cropSize={this.state.cropper.cropSize}
              minZoom={this.state.cropper.minZoom}
              rotation={this.state.cropper.rotation}
              onImageLoaded={this.onImageLoaded}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
              onZoomChange={this.onZoomChange}
            />
          </div>
        ) : (
          <div>
            <div className="crop-area circle-img">
              {imageProcessed && cropper.image && (
                <img
                  className="Image-Placeholder circle-img"
                  src={cropper.image}
                  alt=""
                />
              )}
            </div>
          </div>
        )}

        <div className="rotate-img">
          <label className="upload-icon" for="file-input">
            <img
              style={{ width: "20px", height: "20px" }}
              src="https://cdn-icons-png.flaticon.com/512/503/503623.png"
            />
          </label>
          <input
            id="file-input"
            type="file"
            className="test22"
            accept="image/jpg,image/jpeg"
            onChange={this.onSelectFile}
          />
          {cropping && (
            <p className="btn-clear" onClick={this.rotateImage}>
              ROTATE PHOTO
            </p>
          )}
        </div>
        {cropping && (
          <button className="btn1" onClick={this.cropImage}>
            Done
          </button>
        )}
      </div>
    );
  }
}

export default ImageCrop;
