import React, {Component} from 'react';
import StripeCheckout from 'react-stripe-checkout';
import {membershipPlansActions} from "../../actions/membershipPlansActions";
import {connect} from "react-redux";


const CURRENCY = 'USD';

const fromDollarToCent = amount => parseInt(amount * 100);


class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: {
                name: '',
                price: '',
                description: '',
                id: ''
            }
        }
    }

    handleToken = (token) => {
        const {name, description, amount, id} = this.props;
        this.setState({
            product: {

                name: name,
                price: amount,
                description: description,
                id: id

            }
        });
        console.log("token for stripe ", JSON.stringify(token), this.state.product, token.card);

        const tokens = JSON.stringify(token);
        const products = JSON.stringify(this.state.product);

        this.props.purchaseMembershipPlans(tokens, products);
    };

    render() {
        const {name, description, amount, image} = this.props;
        return (
            <div>
                <StripeCheckout
                    name={name}
                    description={description}
                    amount={fromDollarToCent(amount)}
                    token={this.handleToken}
                    currency={CURRENCY}
                    stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                    zipCode
                    image={image}
                    allowRememberMe
                    locale={"en"}
                />
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        purchaseMembershipPlansReducer: state.purchaseMembershipPlansReducer
    }
};

const actionCreators = {
    purchaseMembershipPlans: membershipPlansActions.purchaseMembershipPlans
};


export default connect(mapState, actionCreators)(Checkout);
