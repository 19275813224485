import React, { Component } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { userActions } from "../../actions/userActions";
import { connect } from "react-redux";
import { history } from "../app/App";
import InfoWindowEx from "./infoWindowEx";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";

class NearMe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latitude: "",
      longitude: "",
      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
      locationDenied: false,
    };
  }

  getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            locationDenied: false,
          });
          console.log("the latitude is ", this.state.longitude);
          this.props.nearMeUsers(this.state.latitude, this.state.longitude);
          console.log("the location is allowed ");
        },
        (positionError) => {
          this.setState({
            locationDenied: true,
          });
          console.log("the location is denied ", positionError);
        }
      );
    } else {
      console.log(" prompt shown");
    }
  };

  async componentDidMount() {
    await this.getGeoLocation();
  }

  onInfoWindowClicked = () => {
    const { name, profileId } = this.state.selectedPlace;

    console.log("onInfoWindowClicked", { name, profileId });
    // eslint-disable-next-line
    {
      profileId
        ? history.push(`/users/${profileId}`)
        : history.push(`/users/me`);
    }
  };

  onMarkerClick = (props, marker) => {
    console.log("onMarkerClick", props);

    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
  };

  onInfoWindowClose = () => {
    console.log("onInfoWindowClose");

    this.setState({
      activeMarker: null,
      selectedPlace: {},
      showingInfoWindow: false,
    });
  };

  onMapClicked = () => {
    console.log("onMapClicked");

    if (this.state.showingInfoWindow) this.onInfoWindowClose();
  };

  displayMarkers = (response) => {
    const { gettingUsers, lastPage, page } = this.props.nearMeUsersReducer;
    console.log("near me users", response);

    return response.map((store, index) => {
      if (response.length - 1 === index) {
        if (lastPage || gettingUsers) {
          gettingUsers
            ? console.log("getting search users already")
            : console.log("last page reached");
        } else {
          this.props.nearMeUsers(
            this.state.latitude,
            this.state.longitude,
            page + 1
          );
        }
      }
      /* const icon = {
                      url: store.image,
                      scaledSize: {width: 64, height: 64}
              };
              */
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: store.lat,
            lng: store.lng,
          }}
          name={store.name}
          distance={store.distance}
          image={store.image.image_350}
          profileId={store.user_id}
          onClick={this.onMarkerClick}
        />
      );
    });
  };

  fetchSetting = () => {
    this.props.getNearMeUsersSettings();
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));

    const {
      latitude,
      longitude,
      locationDenied,
      activeMarker,
      showingInfoWindow,
      selectedPlace,
    } = this.state;
    const { name, distance, image } = selectedPlace;
    const {
      gettingUsers,
      totalFriends,
      nearMeUsers,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.nearMeUsersReducer;
    const {
      fetchingSettings,
      nearMeSettingsInvalidData,
      nearMeErrorMessage,
      nearMeSettingsInternalError,
    } = this.props.fetchNearMeSettingsReducers;
    console.log("response of ", nearMeUsers);
    if (totalFriends > 0) {
      return (
        <div className="home-page-wrapper near-me-page">
          <div className="home-page">
            <div className="top-bar nearme-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.near_me}</h3>
              <div className="right-nav">
                <img
                  onClick={this.fetchSetting}
                  src={require("../../img/near-me-settings.png")}
                  alt="back-button"
                />
              </div>
              <div
                onClick={() => {
                  history.push("/advanceSearch", {
                    initialData: this.responseData,
                  });
                }}
                className="nearme-icon"
              >
                <span class="material-symbols-outlined">search</span>
              </div>
            </div>

            {gettingUsers && <Loader />}
            {invalidData && (
              <div className="near-me-notifications">{errorMessage}</div>
            )}
            {internalError && (
              <div className="near-me-notifications">
                {LOCALE.internal_error}
              </div>
            )}

            {fetchingSettings && <Loader />}
            {nearMeSettingsInvalidData && <div>{nearMeErrorMessage}</div>}
            {nearMeSettingsInternalError && <div>{LOCALE.invalid_data}</div>}

            <Map
              style={{
                height: "94%",
              }}
              google={this.props.google}
              zoom={15}
              mapTypeControl={true}
              mapType={"ROADMAP"}
              streetViewControl={false}
              initialCenter={{ lat: latitude, lng: longitude }}
              centerAroundCurrentLocation={true}
              onClick={this.onMapClicked}
              fullscreenControl={true}
              noClear={true}
            >
              {this.displayMarkers(nearMeUsers)}

              <Marker
                position={{ lat: latitude, lng: longitude }}
                name="You are here"
                icon={process.env.PUBLIC_URL + "/assets/img/icon.png"}
                onClick={this.onMarkerClick}
              />

              <InfoWindowEx
                marker={activeMarker}
                onClose={this.onInfoWindowClose}
                visible={showingInfoWindow}
              >
                <div onClick={this.onInfoWindowClicked}>
                  <h4>{name}</h4>
                  {distance && <h4>{distance + ` away`}</h4>}
                  {image && <img src={image} alt="user" />}
                </div>
              </InfoWindowEx>
            </Map>
          </div>
        </div>
      );
    } else if (locationDenied) {
      return (
        <div className="home-page-wrapper near-me-page">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.near_me}</h3>
              <div className="right-nav">
                <img
                  onClick={this.fetchSetting}
                  src={require("../../img/near-me-settings.png")}
                  alt="back-button"
                />
              </div>
            </div>
            <div className="homepage-content">
              <h3>{LOCALE.sorry_location_permission_required}</h3>
              <br />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-page-wrapper near-me-page">
          <div className="home-page">
            <div className="top-bar">
              <img
                onClick={() => {
                  history.goBack();
                }}
                src={require("../../img/back.png")}
                alt="back-button"
              />
              <h3>{LOCALE.near_me}</h3>
              <div className="right-nav">
                <img
                  onClick={this.fetchSetting}
                  src={require("../../img/near-me-settings.png")}
                  alt="back-button"
                />
              </div>
            </div>
            <Loader />
          </div>
        </div>
      );
    }
  }
}

const mapState = (state) => {
  return {
    nearMeUsersReducer: state.nearMeUsersReducer,
    fetchNearMeSettingsReducers: state.fetchNearMeUsersSettingsReducer,
  };
};
const actionCreators = {
  nearMeUsers: userActions.nearMeUsers,
  getNearMeUsersSettings: userActions.getNearMeUsersSettings,
};

export default connect(
  mapState,
  actionCreators
)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  })(NearMe)
);
