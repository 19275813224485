import {loginConstants} from "../constants/loginConstants";
import {history} from "../components/app/App";
import {apiService} from "../services/apiService";
import {userConstants} from "../constants/userConstants";

export const loginActions = {login, logout,facebookLogin};

function login(username, password) {
    return dispatch => {
        dispatch(request());

        apiService.login(username, password)
            .then(response => {
                const accessToken = response.data.access_token;
                dispatch(success(accessToken));
                console.log('login response=', response.data);
                localStorage.setItem(userConstants.ACCESS_TOKEN, accessToken);
                history.push('/');
            })
            .catch(error => {
                console.log('login error=', error);
                dispatch(failure(error));
            });
    };

    function request() {
        return {
            type: loginConstants.LOGIN_REQUEST,
        };
    }

    function success(accessToken) {
        return {
            type: loginConstants.LOGIN_SUCCESS,
            accessToken: accessToken
        };
    }

    function failure(error) {
        console.log(error);
        return {
            type: loginConstants.LOGIN_FAILURE,
            errorCode: error.response.status
        };
    }
}
function facebookLogin(fbToken) {
    return dispatch => {
        dispatch(request());

        apiService.loginWithFacebook(fbToken)
            .then(response => {
                const accessToken = response.data.access_token;
                dispatch(success(accessToken));
                console.log('login response=', response.data);
                localStorage.setItem(userConstants.ACCESS_TOKEN, accessToken);
                history.push('/');
            })
            .catch(error => {
                console.log('login error=', error);
                dispatch(failure(error));
            });
    };

    function request() {
        return {
            type: loginConstants.FACEBOOK_LOGIN_REQUEST,
        };
    }

    function success(accessToken) {
        return {
            type: loginConstants.FACEBOOK_LOGIN_SUCCESS,
            accessToken: accessToken
        };
    }

    function failure(error) {
        console.log(error);
        return {
            type: loginConstants.FACEBOOK_LOGIN_FAILURE,
            errorCode: error.response.status
        };
    }
}

function logout() {
    return dispatch => {
        dispatch(request());

        apiService.logout()
            .then(response => {
                dispatch(success());
                localStorage.removeItem(userConstants.ACCESS_TOKEN);
                history.push('/login');
            })
            .catch(error => {
                console.log('login error=', error);
                dispatch(failure(error));
            });
    };

    function request() {
        return {
            type: loginConstants.LOGOUT_REQUEST,
        };
    }

    function success() {
        return {
            type: loginConstants.LOGOUT_SUCCESS,
        };
    }

    function failure(error) {
        return {
            type: loginConstants.LOGOUT_FAILURE,
            errorCode: error.response.status
        };
    }
}
