export const loginConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',

    FACEBOOK_LOGIN_REQUEST: 'FACEBOOK_LOGIN_REQUEST',
    FACEBOOK_LOGIN_SUCCESS: 'FACEBOOK_LOGIN_SUCCESS',
    FACEBOOK_LOGIN_FAILURE: 'FACEBOOK_LOGIN_FAILURE',
    LOGOUT: 'LOGOUT',
};
