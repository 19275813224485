export const countryConstants = {
  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",

  GET_STATE_REQUEST: "GET_STATE_REQUEST",
  GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
  GET_STATE_FAILURE: "GET_STATE_FAILURE",

  GET_CITY_REQUEST: "GET_CITY_REQUEST",
  GET_CITY_SUCCESS: "GET_CITY_SUCCESS",
  GET_CITY_FAILURE: "GET_CITY_FAILURE",
};
