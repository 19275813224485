import React, {Component} from 'react';
import {loginActions} from "../../actions/loginActions";
import {connect} from "react-redux";

class Logout extends Component {
    render() {
        return (
            <div>
                <button onClick={() => this.props.logout()}>Logout</button>
            </div>
        );
    }
}

const mapState = (state) => {
    const {accessToken} = state.loginReducer;
    return {accessToken};
};

const actionCreators = {
    logout: loginActions.logout
};

export default connect(mapState, actionCreators)(Logout);
