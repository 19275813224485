import React, { Component } from "react";
import { friendActions } from "../../actions/friendActions";
import { connect } from "react-redux";
import { friendsConstants } from "../../constants/friendsConstants";
import { history } from "../app/App";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";

class RespondFriendButton extends Component {
  componentDidMount() {
    this.checkFriendStatus();
  }

  checkFriendStatus = () => {
    const friendId = this.props.friendId;
    this.props.checkFriendStatus(friendId);
  };

  addFriend = () => {
    const friendId = this.props.friendId;
    this.props.respondFriend(friendId, friendsConstants.FRIEND_SEND_REQUEST);
  };

  cancelFriendRequest = () => {
    const { friend_id } = this.props.checkFriendStatusReducer.responseData.data;
    this.props.respondFriend(friend_id, friendsConstants.FRIEND_CANCEL_REQUEST);
  };

  unFriend = () => {
    console.log(
      "unfriend id",
      this.props.checkFriendStatusReducer.responseData
    );
    const { friend_id } = this.props.checkFriendStatusReducer.responseData.data;
    this.props.respondFriend(friend_id, friendsConstants.FRIEND_UNFRIEND);
  };

  sendMessage = () => {
    const responseData = this.props.responseData;
    responseData.user_id = responseData.id;
    console.log("chat res", responseData);
    history.push("/chatFeed", responseData?.data);
  };

  acceptFriendRequest = () => {
    const { friend_id } = this.props.checkFriendStatusReducer.responseData.data;
    this.props.respondFriend(friend_id, friendsConstants.FRIEND_ACCEPT_REQUEST);
  };

  declineFriendRequest = () => {
    const { friend_id } = this.props.checkFriendStatusReducer.responseData.data;
    this.props.respondFriend(
      friend_id,
      friendsConstants.FRIEND_DECLINE_REQUEST
    );
  };

  getButtons = (status) => {
    switch (status) {
      case "SEND":
        return (
          <button className="btn00" onClick={this.addFriend}>
            {LOCALE.add_friend}
          </button>
        );
      case "SENT":
        return (
          <button className="btn00" onClick={this.cancelFriendRequest}>
            {LOCALE.cancel_request}
          </button>
        );
      case "FRIEND":
        return (
          <div>
            <button className="btn00" onClick={this.unFriend}>
              {LOCALE.unFriend}
            </button>
            <button className="btn01" onClick={this.sendMessage}>
              {LOCALE.send_message}
            </button>
          </div>
        );
      case "ACCEPT":
        return (
          <div>
            <button className="btn00" onClick={this.acceptFriendRequest}>
              {LOCALE.accept_request}
            </button>
            <button className="btn01" onClick={this.declineFriendRequest}>
              {LOCALE.decline}
            </button>
          </div>
        );
      default:
        return <div />;
    }
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const { responding, internalError, invalidData, errorMessage } =
      this.props.respondFriendReducer;
    const { checking, responseData } = this.props.checkFriendStatusReducer;

    const status =
      this.props?.checkFriendStatusReducer?.responseData?.data?.status;
    const res = this.props.respondFriendReducer.responseData;
    console.log(
      "friendstats",
      this.props.checkFriendStatusReducer?.responseData?.data?.status
    );

    return (
      <div className="send-friend-request">
        {(responding || checking) && (
          <button className="btn00" disabled>
            {LOCALE.please_wait}
          </button>
        )}
        {res && !responding && <div>{this.checkFriendStatus()}</div>}
        {internalError && <div>{LOCALE.internal_error}</div>}
        {invalidData && <div>{errorMessage}</div>}
        {status && this.getButtons(status)}
        {/* <div className="send-wink">
          <span class="material-symbols-outlined">add_reaction</span>
        </div> */}
      </div>
    );
  }
}

const actionCreators = {
  respondFriend: friendActions.respondFriend,
  checkFriendStatus: friendActions.checkFriendStatus,
};

const mapState = (state) => {
  return {
    respondFriendReducer: state.respondFriendReducer,
    checkFriendStatusReducer: state.checkFriendStatusReducer,
  };
};

export default connect(mapState, actionCreators)(RespondFriendButton);
