export const viewsConstants = {
  FETCH_SENT_VIEWS_LISTS_REQUEST: "FETCH_SENT_VIEWS_LISTS_REQUEST",
  FETCH_SENT_VIEWS_LISTS_SUCCESS: "FETCH_SENT_VIEWS_LISTS_SUCCESS",
  FETCH_SENT_VIEWS_LISTS_FAILURE: "FETCH_SENT_VIEWS_LISTS_FAILURE",

  FETCH_RECEIVED_VIEWS_LISTS_REQUEST: "FETCH_RECEIVED_VIEWS_LISTS_REQUEST",
  FETCH_RECEIVED_VIEWS_LISTS_SUCCESS: "FETCH_RECEIVED_VIEWS_LISTS_SUCCESS",
  FETCH_RECEIVED_VIEWS_LISTS_FAILURE: "FETCH_RECEIVED_VIEWS_LISTS_FAILURE",

  // RESPOND_GIFT_REQUEST: "RESPOND_GIFT_REQUEST",
  // RESPOND_GIFT_SUCCESS: "RESPOND_GIFT_SUCCESS",
  // RESPOND_GIFT_FAILURE: "RESPOND_GIFT_FAILURE",
};
