import React, { Component } from "react";
import { profileActions } from "../../actions/profileActions";
import Modal from "react-modal";
import { loginActions } from "../../actions/loginActions";
import { connect } from "react-redux";
import { utils } from "../../helper/utils";
import { genderConstants } from "../../constants/genderConstants";
import { history } from "../app/App";
import "../../css/style.css";
import { friendActions } from "../../actions/friendActions";
import RespondFriendButton from "../friends/respondFriendButton";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPencil } from "@fortawesome/free-solid-svg-icons";
import Tab from "../tab/tab";
import { giftActions } from "../../actions/giftActions";
import { winkActions } from "../../actions/winkActions";
import { Gallery } from "react-grid-gallery";

class ViewProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullDetails: false,
      isLoggedFacebook: false,
      giftModal: false,
      giftSelection: false,
      selectedIndex: null,
      currentGiftSelection: null,
      winkModal: false,
      selectedWinkIndex: null,
      currentWinkSelection: null,
    };
  }

  state = {
    on: false,
    giftModal: false,
  };

  toggle = () => {
    this.setState({
      on: !this.state.on,
    });
  };

  handleOpenModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
    window.location.reload();
  };

  handleOpenGift = () => {
    this.setState({
      giftModal: true,
    });
  };

  handleCloseGift = () => {
    this.setState({ giftModal: false });
    // window.location.reload();
  };

  handleOpenWink = () => {
    this.setState({
      winkModal: true,
    });
  };

  handleCloseWink = () => {
    this.setState({ winkModal: false });
    // window.location.reload();
  };

  giftSelected = (gift, index) => {
    this.setState({
      giftSelection: true,
      selectedIndex: index,
      currentGiftSelection: gift,
    });

    // window.location.reload();
  };

  sendGiftAction = (gift) => {
    const idUser = this.props.match.params.userId;
    const giftId = this.state.currentGiftSelection.id;

    this.props.sendGifts(idUser, giftId);
    this.setState({ giftModal: false });
    // window.location.reload();
  };

  winkSelected = (flirt, index) => {
    this.setState({
      giftSelection: true,
      selectedWinkIndex: index,
      currentWinkSelection: flirt,
    });

    console.log("selected wink", this.state.currentWinkSelection);

    // window.location.reload();
  };

  sendWinkAction = () => {
    const idUser = this.props.match.params.userId;
    const winkId = this.state.currentWinkSelection.id;

    this.props.sendWinks(idUser, winkId);
    this.setState({ winkModal: false });
    // window.location.reload();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevUserId = prevProps.match.params.userId;
    const userId = this.props.match.params.userId;

    if (prevUserId !== userId) {
      this.props.getProfile(userId);
    }
  }

  componentDidMount() {
    const userId = this.props.match.params.userId;
    this.props.getProfile(userId);
    this.props.allgifts();
    this.props.allflirts();
    this.props.getMyGallery();
    this.props.getUserGallery(userId);

    // this.props.getProfile(localStorage.getItem(userConstants.USER_ID));
  }

  onShowFullDetailToggled = () => {
    this.setState({ showFullDetails: !this.state.showFullDetails });
  };

  getUserGender = (gender) => {
    switch (gender) {
      case genderConstants.GENDER_TYPE_MALE:
        return genderConstants.GENDER_MALE;

      case genderConstants.GENDER_TYPE_FEMALE:
        return genderConstants.GENDER_FEMALE;

      case genderConstants.GENDER_TYPE_COUPLES:
        return genderConstants.GENDER_COUPLES;
      default:
        return;
    }
  };

  combineProfileQuestions = (profileQuestions) => {
    const nameValue = {};
    for (let i = 0; i < profileQuestions.length; i++) {
      const profileQuestionName = profileQuestions[i].profile_question_name;
      const optionValue = profileQuestions[i].option_value;

      if (nameValue[profileQuestionName]) {
        let optionValues = nameValue[profileQuestionName];
        optionValues += ", " + optionValue;
        nameValue[profileQuestionName] = optionValues;
      } else {
        nameValue[profileQuestionName] = optionValue;
      }
    }

    const list = [];

    for (const name in nameValue) {
      if (nameValue.hasOwnProperty(name)) {
        const item = { name: name, values: nameValue[name] };
        list.push(item);
      }
    }

    return list;
  };

  isMyProfile = () => {
    const idUser = this.props.match.params.userId;
    console.log("new new", this.responseData);
    // const myProfileId = this.responseData?.data?.id;
    console.log("my profiles", idUser);
    // return myProfileId;
    // return userId === "me";

    const myprofileid = localStorage.getItem(userConstants.USER_ID);
    // if (myprofileid == idUser) {
    //   return true;
    // } else {
    //   return false;
    // }

    if (idUser == "me") {
      return true;
    } else {
      return false;
    }
  };

  openEditProfile = () => {
    history.push("/updateProfile", {
      createNew: false,
      initialData: this.responseData,
    });
  };

  openEditProfileQuestion = () => {
    history.push("/profileQuestions", {
      initialData: this.responseData.profile_question_details,
    });
  };

  getDiv = (data) => {
    const { showFullDetails } = this.state;

    this.responseData = {
      ...data,
      profile_questions_details: this.combineProfileQuestions(data.data),
    };

    const profile_questions_complete =
      this.responseData.data.profile_questions_details;

    const {
      id,
      // image,
      image_path,
      name,
      user_age,
      user_seeking,
      user_country,
      user_state,
      user_city,
      user_gender,
      seeking,
      about_me,
      user_my_interest,
      profile_question_details,
    } = this.responseData.data;

    const { loggingOut, internalError } = this.props.logoutReducer;

    console.log("all the props", this.props);

    console.log(
      "gallery props",
      this.props?.myGalleryReducer?.responseData?.data
    );

    console.log("check prof", this.isMyProfile);

    if (this.isMyProfile()) {
      var galleryData = this.props?.myGalleryReducer?.responseData?.data;
      var imageGallery = galleryData?.map((el) => ({
        src: el.path.image_orig,
        width: 300,
        height: 250,
      }));
    } else {
      var galleryData = this.props?.userGalleryReducer?.responseData?.data;
      var imageGallery = galleryData?.map((el) => ({
        src: el.path.image_orig,
        width: 300,
        height: 250,
      }));
    }

    // var galleryData = this.props?.myGalleryReducer?.responseData?.data;
    // var imageGallery = galleryData?.map((el) => ({
    //   src: el.path.image_orig,
    //   width: 300,
    //   height: 250,
    // }));

    const customStyles = {
      content: {
        maxHeight: "520px",
      },
    };

    return (
      <div>
        {/* <div className="image-wrapper">
          <img className="crop-area2" src={image_path.image_orig} alt="user" />
        </div> */}

        <div className="profile-view-content">
          <div>
            <img
              className="profile-image"
              src={image_path.image_250}
              alt="notification-icon"
            />

            {this.isMyProfile() && (
              <div className="logout-button">
                {" "}
                <span
                  onClick={this.handleOpenModal}
                  class="material-symbols-outlined"
                >
                  logout
                </span>
              </div>
            )}

            <Modal
              isOpen={this.state.showModal}
              contentLabel="Minimal Modal Example"
              ariaHideApp={false}
              onClose={this.handleCloseModal}
            >
              <img
                className="cancel-icon"
                onClick={this.handleCloseModal}
                src={require("../../img/cancel.png")}
                alt="cancel-button"
              />
              <h1>Logout</h1>
              {loggingOut ? (
                <div> {LOCALE.logging_out}</div>
              ) : (
                <div>
                  {internalError && <div>{LOCALE.internal_error}</div>}
                  <h3>{LOCALE.sure_want_to_logout}</h3>
                  <button disabled={loggingOut} onClick={this.handleCloseModal}>
                    {LOCALE.no}
                  </button>
                  <button
                    disabled={loggingOut}
                    onClick={() => this.props.logout()}
                  >
                    {LOCALE.yes}
                  </button>
                </div>
              )}
            </Modal>
            <div className="profile-view-name-section">
              <div className="profile-view-left">
                <label className="profile-view-name">
                  {name}, {user_age}
                </label>
                <div className="profile-view-state">
                  {/* <label>
                  <span>
                    <i class="fa-sharp fa-solid fa-location-dot"></i>{" "}
                  </span>
                  <strong>{user_country}</strong>{" "}
                  {user_state !== "None" ? <label>, {user_state}, </label> : ""}
                  <label>{user_city}</label>
                </label> */}
                  <div className="card-location-container">
                    <FontAwesomeIcon
                      className="card-location-icon"
                      icon={faLocationDot}
                    />
                    <p>{`${
                      user_city + ", " + user_state + ", " + user_country
                    }`}</p>
                  </div>
                </div>
              </div>
              {this.isMyProfile() && (
                <div className="profile-view-right">
                  <div
                    onClick={this.openEditProfile}
                    className="edit-profile-button"
                  >
                    <FontAwesomeIcon
                      className="edit-pencil-icon"
                      icon={faPencil}
                    />
                    <p>Edit</p>
                  </div>
                </div>
              )}
              {!this.isMyProfile() && (
                <div>
                  <div className="profile-view-right">
                    <div
                      // onClick={this.openEditProfile}
                      className="gift-profile-button"
                    >
                      <span
                        onClick={this.handleOpenGift}
                        class="material-symbols-outlined"
                      >
                        redeem
                      </span>
                    </div>
                  </div>
                  <Modal
                    isOpen={this.state.giftModal}
                    contentLabel="Gift Modal"
                    ariaHideApp={false}
                  >
                    <img
                      className="cancel-icon"
                      onClick={this.handleCloseGift}
                      src={require("../../img/cancel.png")}
                      alt="cancel-button"
                    />
                    <h3>Gifts</h3>

                    {this.props.giftsReducer.gifts.map((gift, index) => (
                      <img
                        key={index}
                        onClick={(e) => {
                          this.giftSelected(gift, index);
                        }}
                        class={
                          this.state.giftSelection &&
                          this.state.selectedIndex == index
                            ? "gift-selector gift-img"
                            : "gift-img"
                        }
                        src={gift.image}
                      />
                    ))}

                    <div>
                      <button onClick={this.sendGiftAction} class="btn00">
                        Send
                      </button>
                    </div>
                  </Modal>
                </div>
              )}
            </div>
          </div>

          {this.isMyProfile() && (
            <div className="navigation-icon-section">
              <span
                onClick={() => {
                  history.push("/accountSettings", {
                    initialData: this.responseData,
                  });
                }}
                class="material-symbols-outlined"
              >
                settings
              </span>
              <span
                onClick={() => {
                  history.push("/viewFriendsList", {
                    initialData: this.responseData,
                  });
                }}
                class="material-symbols-outlined"
              >
                group
              </span>

              <span
                onClick={() => {
                  history.push("/winks", {
                    initialData: this.responseData,
                  });
                }}
                class="material-symbols-outlined"
              >
                add_reaction
              </span>
              <span
                onClick={() => {
                  history.push("/views", {
                    initialData: this.responseData,
                  });
                }}
                class="material-symbols-outlined"
              >
                visibility
              </span>
              <span
                onClick={() => {
                  history.push("/gifts", {
                    initialData: this.responseData,
                  });
                }}
                class="material-symbols-outlined"
              >
                redeem
              </span>
            </div>
          )}

          {/* <RespondFriendButton friendId={id} responseData={this.responseData} /> */}
          {!this.isMyProfile() && (
            <div>
              <div className="friend-wink">
                <RespondFriendButton
                  friendId={id}
                  responseData={this.responseData}
                />

                <div className="send-wink">
                  <span
                    onClick={this.handleOpenWink}
                    class="material-symbols-outlined"
                  >
                    add_reaction
                  </span>
                </div>
              </div>
              <Modal
                isOpen={this.state.winkModal}
                contentLabel="Wink Modal"
                ariaHideApp={false}
                style={customStyles}
              >
                <div className="wink-title-bar">
                  <img
                    className="cancel-icon"
                    onClick={this.handleCloseWink}
                    src={require("../../img/cancel.png")}
                    alt="cancel-button"
                  />
                  <h3>Send Winks</h3>
                </div>
                <div className="flirts-list">
                  {this.props.flirtsReducer.flirts.map((flirt, index) => (
                    <p
                      key={index}
                      onClick={(e) => {
                        this.winkSelected(flirt, index);
                      }}
                      class={
                        this.state.giftSelection &&
                        this.state.selectedWinkIndex == index
                          ? "gift-selector flirt-list"
                          : "flirt-list"
                      }
                    >
                      {flirt.text}
                    </p>
                  ))}
                </div>

                <div className="send-wink-button">
                  <button onClick={this.sendWinkAction} class="btn00">
                    Send
                  </button>
                </div>
              </Modal>
            </div>
          )}
          {/* <div className="profile-view-name-wrapper">
            <label className="profile-view-name">
              {name}, {user_age}
            </label>
            <label className="profile-view-gender">
              <strong>{user_gender}</strong>
            </label>
          </div> */}

          {/* {user_state !== "None" ? <label>{user_state}, </label> : ""}
          <label>{user_city}</label> */}
          <label className="profile-view-about"> {about_me}</label>
        </div>

        <div className="basic-details">
          <div className="details-section">
            <div className="detail-wrapper">
              <label>{LOCALE.i_am} </label>
              <label> {user_gender}</label>
            </div>
            <div className="detail-wrapper">
              <label>{LOCALE.seeking_a} </label>
              <label> {user_seeking}</label>
            </div>
            {/* <div className="detail-wrapper">
              <label>{LOCALE.ages}</label>
              <label> {user_age}</label>
            </div>
            <div className="detail-wrapper">
              <label>{LOCALE.profile_country}</label>
              <label> {user_country}</label>
            </div>
            {user_state !== "None" ? (
              <div>
                <div className="detail-wrapper">
                  <label>{LOCALE.profile_state}</label>
                  <label> {user_state}</label>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="detail-wrapper">
              <label>{LOCALE.profile_city}</label>
              <label> {user_city}</label>
            </div> */}
            <div className="detail-wrapper">
              <label>{LOCALE.my_interests}</label>
              <label> {user_my_interest}</label>
            </div>
            <div className="gallery-title">
              <label>Gallery</label>
            </div>
            <Gallery className="img-gallery" images={imageGallery} />
          </div>
          {/* <div>
            <Gallery className="img-gallery" images={imageGallery} />
            </div> */}
          {/* <div className="details-section">
            <label> {user_gender}</label>
            <label> {user_seeking}</label>
            <label> {user_age}</label>
            <label> {user_country}</label>
            {user_state !== "None" ? (
              <div>
                <label> {user_state}</label>
              </div>
            ) : (
              ""
            )}
            <label> {user_city}</label>
            <label> {user_my_interest}</label>
          </div> */}
          {
            <button
              className="btn-clear"
              onClick={this.onShowFullDetailToggled}
            >
              {" "}
              {!showFullDetails
                ? LOCALE.show_full_details
                : LOCALE.hide_full_details}
            </button>
          }
        </div>

        {showFullDetails && (
          <div className="basic-details2">
            {profile_question_details.map((item, i) => (
              <div className="basic-details-inner" key={i}>
                <div className="detail-wrapper" style={{ display: "grid" }}>
                  <label>{item.name}</label>
                  <label> {item.option_value}</label>
                </div>
                {/* <div className="details-section">
                  <label>{item.name} :</label>
                </div>
                <div className="details-section">
                  <label> {item.option_value}</label>
                </div> */}
              </div>
            ))}
            {profile_question_details.length === 0 && (
              <div>{LOCALE.no_profile_available}</div>
            )}
            {this.isMyProfile() && (
              <button className="btn01" onClick={this.openEditProfileQuestion}>
                {LOCALE.edit_profile_questions}
              </button>
            )}
          </div>
        )}

        {this.isMyProfile() && (
          <div className="tab-footer">
            <Tab />
          </div>
        )}
      </div>
    );
  };

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      gettingProfile,
      responseData,
      internalError,
      invalidData,
      errorMessage,
    } = this.props.getProfileReducer;
    return (
      <div className="home-page-wrapper">
        <div className="home-page">
          <div className="top-bar">
            <img
              onClick={() => {
                history.goBack();
              }}
              src={require("../../img/back.png")}
              alt="back-button"
            />
            <h3>{LOCALE.profile}</h3>
            <div className="right-nav" onClick={this.toggle}>
              <span></span>
              <span></span>
              <span></span>
            </div>

            {this.state.on && (
              <div className="basic-action">
                {this.isMyProfile() && (
                  <div>
                    <div
                      className="div-show"
                      onClick={() => {
                        history.push("/viewFriendsList", {
                          initialData: this.responseData,
                        });
                      }}
                    >
                      <button className="btn-clear">
                        {LOCALE.all_friends}
                      </button>
                    </div>
                    <div
                      className="div-show"
                      onClick={() => {
                        history.push("/viewSentFriendRequests");
                      }}
                    >
                      <button className="btn-clear">
                        {LOCALE.pending_requests}
                      </button>
                    </div>
                    <div
                      className="div-show"
                      onClick={() => {
                        history.push("/pendingFriendRequests");
                      }}
                    >
                      <button className="btn-clear">
                        {LOCALE.friend_requests}
                      </button>
                    </div>
                  </div>
                )}
                {!this.isMyProfile() && (
                  <div
                    className="div-show"
                    onClick={() => {
                      history.push("/reportUser", {
                        initialData: this.responseData,
                      });
                    }}
                  >
                    <button className="btn-clear">{LOCALE.report_user}</button>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="image-wrapper2">
            {responseData && this.getDiv(responseData)}
          </div>

          {gettingProfile && <Loader />}
          {internalError && <div>{LOCALE.internal_error}</div>}
          {invalidData && <div>{errorMessage}</div>}

          {/* {this.isMyProfile() && !gettingProfile && this.responseData && (
            <div className="view-profile-footer">
              <button className="btn01" onClick={this.openEditProfile}>
                {LOCALE.edit_profile}
              </button>
              {responseData && responseData.logged_in_from !== "facebook" && (
                <button
                  className="btn01"
                  onClick={() => {
                    history.push("/accountSettings", {
                      initialData: this.responseData,
                    });
                  }}
                >
                  {LOCALE.account_settings}
                </button>
              )}
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

const actionCreators = {
  getProfile: profileActions.getProfile,
  getMyGallery: profileActions.getMyGallery,
  getUserGallery: profileActions.getUserGallery,
  sendFriendRequest: friendActions.sendFriendRequest,
  logout: loginActions.logout,
  allgifts: giftActions.getAllGiftsList,
  sendGifts: giftActions.sendGifts,
  allflirts: winkActions.getAllFlirtsList,
  sendWinks: winkActions.sendWinks,
};

const mapState = (state) => {
  return {
    getProfileReducer: state.getProfileReducer,
    sendFriendRequestReducer: state.sendFriendRequestReducer,
    logoutReducer: state.logoutReducer,
    giftsReducer: state.getAllGiftsListReducer,
    sendGiftReducer: state.sendGiftReducer,
    flirtsReducer: state.getAllFlirtsReducer,
    sendWinkReducer: state.sendWinkReducer,
    myGalleryReducer: state.getMyGalleryReducer,
    userGalleryReducer: state.getUserGalleryReducer,
  };
};
export default connect(mapState, actionCreators)(ViewProfile);
