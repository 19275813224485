import { giftsConstants } from "../constants/giftsConstants";

const initialState = {
  getGiftsList: {
    gettingGifts: false,
    invalidData: false,
    internalError: false,
    sentgifts: [],
    receivedgifts: [],
    nextPage: 0,
    errorMessage: "",
  },
  getAllGifts: {
    gettingGifts: false,
    invalidData: false,
    internalError: false,
    gifts: [],
    nextPage: 0,
    errorMessage: "",
  },

  sendGifts: {
    gettingGifts: false,
    invalidData: false,
    internalError: false,
    sentgift: [],
    nextPage: 0,
    errorMessage: "",
  },
};

const getSentGiftsListReducer = (state = initialState.getGiftsList, action) => {
  switch (action.type) {
    case giftsConstants.FETCH_SENT_GIFTS_LISTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getGiftsList, gettingGifts: true };
      }
      return { ...state, gettingGifts: true };
    case giftsConstants.FETCH_SENT_GIFTS_LISTS_SUCCESS:
      let { sentgifts } = state;
      if (action.reset) {
        sentgifts = [];
      }

      const newGifts = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      for (let i = 0; i < newGifts.length; i++) {
        sentgifts.push(newGifts[i]);
      }
      console.log("sent gifts list=", sentgifts);
      return {
        ...state,
        sentgifts,
        totalGifts: sentgifts.length,
        gettingGifts: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case giftsConstants.FETCH_SENT_GIFTS_LISTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getGiftsList,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getGiftsList,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const getReceivedGiftsListReducer = (
  state = initialState.getGiftsList,
  action
) => {
  switch (action.type) {
    case giftsConstants.FETCH_RECEIVED_GIFTS_LISTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getGiftsList, gettingGifts: true };
      }
      return { ...state, gettingGifts: true };
    case giftsConstants.FETCH_RECEIVED_GIFTS_LISTS_SUCCESS:
      let { receivedgifts } = state;
      if (action.reset) {
        receivedgifts = [];
      }

      const newGifts = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      for (let i = 0; i < newGifts.length; i++) {
        receivedgifts.push(newGifts[i]);
      }
      console.log("getFriendsLists received gifts=", receivedgifts);
      return {
        ...state,
        receivedgifts,
        totalGifts: receivedgifts.length,
        gettingGifts: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case giftsConstants.FETCH_RECEIVED_GIFTS_LISTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getGiftsList,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getGiftsList,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const getAllGiftsListReducer = (state = initialState.getAllGifts, action) => {
  switch (action.type) {
    case giftsConstants.FETCH_ALL_GIFTS_LISTS_REQUEST:
      if (action.reset) {
        return { ...initialState.getAllGifts, gettingGifts: true };
      }
      return { ...state, gettingGifts: true };
    case giftsConstants.FETCH_ALL_GIFTS_LISTS_SUCCESS:
      let { gifts } = state;
      if (action.reset) {
        gifts = [];
      }

      const newGifts = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;
      gifts = [];
      for (let i = 0; i < newGifts.length; i++) {
        gifts.push(newGifts[i]);
      }
      console.log("all gifts list=", gifts);
      return {
        ...state,
        gifts,
        totalGifts: gifts.length,
        gettingGifts: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case giftsConstants.FETCH_ALL_GIFTS_LISTS_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.getAllGifts,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.getAllGifts,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

const sendGiftReducer = (state = initialState.sendGifts, action) => {
  switch (action.type) {
    case giftsConstants.SEND_GIFT_REQUEST:
      if (action.reset) {
        return { ...initialState.sendGifts, gettingGifts: true };
      }
      return { ...state, gettingGifts: true };
    case giftsConstants.SEND_GIFT_SUCCESS:
      let { sentgift } = state;
      if (action.reset) {
        sentgift = [];
      }

      const newGifts = action.responseData;
      console.log("error69", action);
      //   const { pagination } = action.responseData.meta;

      sentgift.push(newGifts);

      console.log("sent gift response", sentgift);
      return {
        ...state,
        sentgift,
        totalGifts: sentgift.length,
        gettingGifts: false,
        // page: pagination.current_page,
        // lastPage: pagination.current_page === pagination.total_pages,
      };
    case giftsConstants.SEND_GIFT_FAILURE:
      switch (action.errorCode) {
        case 400:
          return {
            ...initialState.sendGifts,
            invalidData: true,
            errorMessage: action.errorMessage,
          };
        default:
          return {
            ...initialState.sendGifts,
            internalError: true,
          };
      }
    default:
      return state;
  }
};

export {
  getSentGiftsListReducer,
  getReceivedGiftsListReducer,
  getAllGiftsListReducer,
  sendGiftReducer,
};
