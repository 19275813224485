import React, { Component } from "react";
import { membershipPlansActions } from "../../actions/membershipPlansActions";
import { history } from "../app/App";
import { connect } from "react-redux";
import Checkout from "./Checkout";
import { utils } from "../../helper/utils";
import Modal from "react-modal";
import Loader from "../Loader/Loader";
import { default as LOCALE } from "../../localization";
import { userConstants } from "../../constants/userConstants";

class MembershipPlansList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  componentDidMount() {
    this.props.getMembershipPlans();
  }

  handleOpenModal = () => {
    this.setState({
      showModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };
  handleImageClick = (membershipId) => {
    history.push(`/membership/${membershipId}`, {
      membershipIds: membershipId,
    });
  };

  showPlanList(responseData) {
    return (
      <div className="scrolly">
        {responseData.map((membershipPlan, i) => (
          <div className="membership-plan" key={i}>
            <img
              onClick={this.handleImageClick.bind(this, membershipPlan.id)}
              key={i}
              className="img-slide"
              src={membershipPlan.image_path}
              alt="user"
            />
            <br />

            <label
              className="plan-name"
              onClick={this.handleImageClick.bind(this, membershipPlan.id)}
            >{`${membershipPlan.name}`}</label>
            <label className="plan-discription">{`${membershipPlan.description}`}</label>
            <label className="plan-price">{`$${membershipPlan.price}`}</label>
            <label className="plan-time">{`per ${utils.getDuration(
              membershipPlan.no_of_days
            )}`}</label>
            <label className="plan-time">{`Unlocks ${membershipPlan.no_of_days} Features `}</label>
            <button
              className="btn-clear"
              onClick={this.handleImageClick.bind(this, membershipPlan.id)}
            >
              {LOCALE.show_features}{" "}
            </button>
            {/*<label>{`${membershipPlan.no_of_days} Days`}</label>*/}
            {/*<div>
                                    {
                                        membershipPlan.premium_access_feature.map((accessFeature, j) => (
                                            <div key={j}>
                                                <div>
                                                <ul type="disc">
                                                    <li>{`${accessFeature}`}</li>
                                                </ul>
                                                </div>
                                            </div>
                                        ))

                                    }
                               </div>*/}
            {parseInt(membershipPlan.purchase_status) === 0 ? (
              <Checkout
                name={membershipPlan.name}
                description={membershipPlan.description}
                amount={membershipPlan.price}
                image={membershipPlan.image_path}
                id={membershipPlan.id}
              />
            ) : (
              <div className="subscribed-btn">
                <button disabled={true}>
                  <span>&#10003;</span> {LOCALE.subscribed}
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }

  render() {
    LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
    const {
      gettingPlans,
      responseData,
      invalidData,
      internalError,
      errorMessage,
    } = this.props.membershipPlansReducer;
    const {
      purchasingPlan,
      invalidDataPlan,
      internalErrorPlan,
      errorMessagePlan,
      showModal,
      purchaseResponseData,
    } = this.props.purchaseMembershipPlansReducer;
    console.log("the response of response data is ", responseData);
    return (
      <div className="home-page-wrapper membership-plan-page">
        <div className="home-page">
          <div className="top-bar">
            <img
              onClick={() => {
                history.goBack();
              }}
              src={require("../../img/back.png")}
              alt="back-button"
            />
            <h3>{LOCALE.membership_plans}</h3>
          </div>
          {gettingPlans && <Loader />}

          <div className="homepage-content">
            {showModal && (
              <div>
                <Modal
                  isOpen={true}
                  contentLabel="Minimal Modal Example"
                  ariaHideApp={false}
                >
                  {purchaseResponseData ? (
                    <div>
                      <h3>{LOCALE.payment_successful}</h3>
                      <img src={require("../../img/tick.png")} alt="done" />
                      <br />
                      <button
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        {LOCALE.membership_lists}
                      </button>
                      <button
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        {LOCALE.home}
                      </button>
                    </div>
                  ) : (
                    <div>
                      {purchasingPlan && <Loader />}
                      {internalErrorPlan && (
                        <div>
                          {LOCALE.internal_error} <br />
                          <button
                            onClick={() => {
                              window.location.reload();
                            }}
                          >
                            {LOCALE.membership_plans}
                          </button>
                          <button
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            {LOCALE.home}
                          </button>
                        </div>
                      )}
                      {invalidDataPlan && (
                        <div>
                          {errorMessagePlan}
                          <br />
                          <button
                            onClick={() => {
                              window.location.reload();
                            }}
                          >
                            {LOCALE.membership_lists}
                          </button>
                          <button
                            onClick={() => {
                              history.goBack();
                            }}
                          >
                            {LOCALE.home}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </Modal>
              </div>
            )}

            {internalError && <div>{LOCALE.internal_error}</div>}
            {invalidData && <div>{errorMessage}</div>}
            {!responseData || responseData.length !== 0 ? (
              this.showPlanList(responseData)
            ) : (
              <h4>Loading</h4>
              // <h2> {LOCALE.sorry_no_membership_plans} </h2>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    membershipPlansReducer: state.getMembershipPlansReducer,
    purchaseMembershipPlansReducer: state.purchaseMembershipPlansReducer,
  };
};
const actionCreators = {
  getMembershipPlans: membershipPlansActions.getMembershipPlans,
};
export default connect(mapState, actionCreators)(MembershipPlansList);
